import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { AccountStatusEnum, accountSchema } from "@models/Account";
import { collectionSchema } from "@models/Collection";
import { Organization } from "@models/Organization";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export const getFullAccountsItemSchema = accountSchema.extend({
  collections: z.array(
    collectionSchema
      .pick({
        id: true,
        name: true,
        status: true,
        brandId: true,
      })
      .extend({
        status: AccountStatusEnum,
      }),
  ),
  portfolios: z.array(
    portfolioSchema.extend({
      sellers: z.array(
        organizationRepresentativeSchema.pick({
          id: true,
          firstName: true,
          lastName: true,
          role: true,
        }),
      ),
      manager: organizationRepresentativeSchema.pick({
        id: true,
        firstName: true,
        lastName: true,
        role: true,
      }),
    }),
  ),
});

export type GetFullAccountsResponseItem = z.infer<
  typeof getFullAccountsItemSchema
>;
export type GetFullAccountsResponse = Array<GetFullAccountsResponseItem>;

export const getFullAccountsEndpoint = {
  path: (organizationId: Organization["id"]) =>
    `/organizations/${organizationId}/accounts/full`,
  call: (organizationId: Organization["id"]) =>
    axiosInstance
      .get<GetFullAccountsResponse>(
        getFullAccountsEndpoint.path(organizationId),
      )
      .then((res) => res.data),
};

export type GetFullAccountsParams = Organization["id"];

export const useGetFullAccounts = (organizationId: GetFullAccountsParams) =>
  useQuery({
    queryKey: getAPIQueryKey(getFullAccountsEndpoint.path(organizationId)),
    queryFn: () => getFullAccountsEndpoint.call(organizationId),
  });
