import { isSameDay } from "date-fns";

import { OpeningDay } from "@models/Showroom";
import { Collection } from "@models/old/Collection";
import { Seller } from "@models/old/Seller";
import {
  AppointmentFormat,
  AppointmentTypeEnum,
  ShowroomSeason,
  VirtualMeetingApps,
} from "@models/types/enums";

export function isOpenDay(
  showroom: { openingDays: { day: Date }[] },
  day: Date,
) {
  return showroom.openingDays.some((d) => isSameDay(d.day, day));
}

interface WalkthroughAppointmentType {
  type: AppointmentTypeEnum.WALKTHROUGH;
  duration: number;
}

interface BuyingAppointmentType {
  type: AppointmentTypeEnum.BUYING_APPOINTMENT;
  duration: number;
  collectionId: string;
}

export default interface Showroom {
  salesCampaignId: string | null;
  id: string;
  name: string;
  season: ShowroomSeason;
  year: number;
  formattedAddress: string;
  timezone: string;
  directions: string | null;
  openingHour: Date;
  closingHour: Date;
  lunchBreakStartingHour: Date | null;
  lunchBreakEndingHour: Date | null;
  customOpeningHoursByDay: Record<
    string,
    { openingHour: Date | null; closingHour: Date | null }
  > | null;
  openingDays: OpeningDay[];
  collections: Collection[];
  sellers: (Seller & {
    appointmentTypes: AppointmentTypeEnum[];
    virtualMeetingAppLinks: Record<VirtualMeetingApps, string>;
    languages: string[];
  })[];
  appointmentTypes: AppointmentTypeEnum[];
  appointmentFormats: AppointmentFormat[];
  appointmentIncrement: number | null;
  specialRequestsAllowed: boolean;
  city: string;
  postalCode: string;
  countryCode: string;
  addressComponents?: string; // serialized json with results from place-autocomplete
  appointmentTypesDuration: (
    | WalkthroughAppointmentType
    | BuyingAppointmentType
  )[];
  lastAllowedBookingDate: Date | null;
  numberOfSeats: number | null;
}
