import React, { useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { TablePagination } from "@components/data-display/TablePagination";
import Loading from "@components/feedback/Loading";
import { formatDateToISO } from "@helpers/Date";
import { Account } from "@models/Account";
import { Appointment as AppointmentModel } from "@models/Appointment";
import { TodaysAppointmentsRowsDisplay } from "@pages/(frontoffice)/dashboard/todays-appointments-rows-display";
import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import ConditionalRender from "@shared/components/on";

interface Appointment {
  id: AppointmentModel["id"];
  status: AppointmentModel["status"];
  account: Pick<Account, "id">;
}

interface Seller {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
  languages: string[];
  virtualMeetingAppLinks: Partial<
    Record<"ZOOM" | "GOOGLE_MEET" | "MICROSOFT_TEAMS", string | undefined>
  > | null;
}

export function usePaginationState(filteredAppointments: Appointment[]) {
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfItemsPerPage = 20;
  const {
    paginatedAppointments,
    canGoToPreviousPage,
    canGoToNextPage,
    allPageNumbers,
    numberOfAppointmetntsPerSeller,
  } = useMemo(() => {
    const startIndex = (currentPage - 1) * numberOfItemsPerPage;
    const endIndex = startIndex + numberOfItemsPerPage;
    const appointments = filteredAppointments.slice(startIndex, endIndex);
    const appointmetntsPerSeller = filteredAppointments.length;

    const totalNumberOfPages = Math.ceil(
      filteredAppointments.length / numberOfItemsPerPage,
    );

    const pageNumbers: (number | string)[] = [];
    if (totalNumberOfPages > 5) {
      // add the first page
      pageNumbers.push(1);
      if (currentPage > 3) {
        // add ellipsis
        pageNumbers.push("...");
      }
      // add page numbers around the current page
      for (
        // ensure we start at page 2 or two pages before the currentPage
        let i = Math.max(2, currentPage - 2);
        // ensure we end at the second last page or two pages after the currentPage
        i <= Math.min(totalNumberOfPages - 1, currentPage + 2);
        i++
      ) {
        pageNumbers.push(i);
      }
      // add ellipsis after current page range
      if (currentPage < totalNumberOfPages - 2) {
        pageNumbers.push("...");
      }
      // add the last page
      pageNumbers.push(totalNumberOfPages);
    } else {
      // list all page numbers
      for (let i = 1; i <= totalNumberOfPages; i++) {
        pageNumbers.push(i);
      }
    }

    return {
      paginatedAppointments: appointments,
      canGoToPreviousPage: currentPage > 1,
      canGoToNextPage: currentPage < totalNumberOfPages,
      allPageNumbers: pageNumbers,
      numberOfAppointmetntsPerSeller: appointmetntsPerSeller,
    };
  }, [filteredAppointments, currentPage]);

  return {
    paginatedAppointments,
    canGoToPreviousPage,
    canGoToNextPage,
    allPageNumbers,
    numberOfAppointmetntsPerSeller,
    currentPage,
    setCurrentPage,
    numberOfItemsPerPage,
  };
}

function TodaysAppointments() {
  const { t } = useTranslation();

  //* fetch organization id, showroom id and today's date for GetDailyCalendarEndpoint query
  // organization Id
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  // showroom Ids
  const { data: allOngoingShowrooms = [], status: allOngoingShowroomsStatus } =
    GetOngoingShowroomsEndpoint.useHook({
      organizationId,
    });

  // today's date
  const todaysDate = formatDateToISO(new Date());

  //* get daily calendar data
  const { data: rawDailyCalendar, status: dailyCalendarFetchStatus } =
    useQueries({
      queries: allOngoingShowrooms.map((ongoingShowroom) =>
        GetDailyCalendarEndpoint.query({
          organizationId,
          showroomId: ongoingShowroom.id,
          dayAsString: todaysDate,
        }),
      ),
      combine: (queries) => ({
        data: queries.flatMap((q) => q.data || []),
        error: queries.find((q) => q.error)?.error,
        status:
          queries.find((q) => q.status === "error")?.status ||
          queries.find((q) => q.status === "pending")?.status ||
          "success",
      }),
    });

  const allAppointments = rawDailyCalendar
    .filter((day) => day.appointments.length > 0)
    .flatMap((day) => day.appointments)
    .filter((app) => app.type !== "BUSY")
    .sort((a, b) => {
      // convert start times to local date/time and get the timestamps
      const startTimeA = new Date(
        a.startTime.toLocalDate(a.showroom.timezone),
      ).getTime();
      const startTimeB = new Date(
        b.startTime.toLocalDate(b.showroom.timezone),
      ).getTime();

      // compare the timestamps
      return startTimeA - startTimeB;
    });

  // set selectedSellerId
  const [selectedSellerId, setSelectedSellerId] = useState<string | "all">(
    "all",
  );

  // set the filtered appointments
  const filteredAppointments = useMemo(() => {
    if (selectedSellerId === "all") {
      return allAppointments;
    }

    return allAppointments.filter(
      (appointment) => appointment.seller.id === selectedSellerId,
    );
  }, [allAppointments, selectedSellerId]);

  // find sellers that have appointments for today
  const todaysSellers: Seller[] = useMemo(() => {
    // extract unique seller IDs
    const uniqueSellerIds = Array.from(
      new Set(allAppointments.map((appointment) => appointment.seller.id)),
    );

    // map unique IDs back to seller objects
    const uniqueSellers = uniqueSellerIds
      .map((id) => {
        const appt = allAppointments.find(
          (appointment) => appointment.seller.id === id,
        );
        // handle cases where appointment might be undefined
        return appt?.seller ?? null;
      })
      .filter((seller): seller is Seller => seller !== null);

    return uniqueSellers;
  }, [allAppointments]);

  const isLoading =
    dailyCalendarFetchStatus === "pending" ||
    allOngoingShowroomsStatus === "pending";

  // pagination data
  const paginationState = usePaginationState(filteredAppointments);

  return (
    <div className="col-span-7">
      <div
        style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
        className={` bg-white p-6 mb-4 rounded-lg grow-x col-span-9`}
      >
        <div className="flex flex-row justify-between">
          <h3 className="font-bold">
            {" "}
            {t("Dashboard.todays-appointments.title")}
          </h3>
          {todaysSellers.length > 1 && (
            <div className="flex flex-row border border-transparent rounded-md">
              <p className="flex items-center text-sm p-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-l-md">
                {t("Dashboard.todays-appointments.for-seller")}
              </p>
              <select
                id="seller-dropdown"
                value={selectedSellerId}
                onChange={(e) => {
                  setSelectedSellerId(e.target.value as string);
                  paginationState.setCurrentPage(1);
                }}
                className="form-select block border rounded-r-md shadow-sm border-primaryElectricBlue focus:border-primaryElectricBlue bg-primaryLightElectricBlue sm:text-sm text-primaryElectricBlue caret-primaryElectricBlue"
              >
                <option value="all">All</option>
                {todaysSellers?.map((seller) => (
                  <option key={seller.id} value={seller.id}>
                    {`${seller.firstName} ${seller.lastName[0]}.`}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        {isLoading ? (
          <Loading type="screen" />
        ) : (
          <ConditionalRender
            renderIf={allAppointments.length > 0}
            fallback={
              <div className="flex items-center justify-center h-64">
                <p className="text-lg text-gray-500">
                  {t("Dashboard.todays-appointments.no-appointments")}
                </p>
              </div>
            }
          >
            <div>
              <div className="mt-7 mb-2 grid grid-cols-16">
                <p className="font-light text-left px-2 text-sm">{}</p>
                <p className="font-light text-left px-2 text-sm col-span-3">
                  {t("Dashboard.todays-appointments.account")}
                </p>
                <p className="font-light text-left px-2 text-sm col-span-3">
                  {t("Dashboard.todays-appointments.collection")}
                </p>
                <p className="font-light text-left px-2 text-sm col-span-2">
                  {t("Dashboard.todays-appointments.otb")}
                </p>
                <p className="font-light text-left px-2 text-sm col-span-2">
                  {t("Dashboard.todays-appointments.target-budget")}
                </p>
                <p className="font-light text-left px-2 text-sm col-span-2">
                  {t("Dashboard.todays-appointments.duration")}
                </p>
                <p className="font-light text-left px-2 text-sm">
                  {t("Dashboard.todays-appointments.seller")}
                </p>
                <p className="font-light text-left px-2 text-sm">{}</p>
              </div>
              <hr />
            </div>
            <div>
              {paginationState.paginatedAppointments.length > 0 ? (
                paginationState.paginatedAppointments.map((appointment) =>
                  appointment ? (
                    <TodaysAppointmentsRowsDisplay
                      key={appointment.id}
                      appointmentId={appointment.id}
                      accountId={appointment.account?.id}
                    />
                  ) : null,
                )
              ) : (
                <div className="flex items-center justify-center h-64">
                  <p className="text-lg text-gray-500">
                    {t("Dashboard.todays-appointments.no-results")}
                  </p>
                </div>
              )}
            </div>
          </ConditionalRender>
        )}
        <TablePagination paginationState={paginationState} />
      </div>
    </div>
  );
}

export default TodaysAppointments;
