import React from "react";

import Button from "@components/data-entry/Button";

interface StatisticProps {
  title: string;
  value: number;
  onClick?: () => void;
  className?: string;
}

function Statistic(props: StatisticProps) {
  const { title, value, onClick, className } = props;

  return (
    <Button
      theme="NONE"
      style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
      className={`flex items-center gap-4 sm:flex-col sm:gap-2 bg-white p-6 rounded-lg text-primaryDarkGrey grow-x hover:border hover:border-solid hover:border-primaryElectricBlue hover:text-primaryElectricBlue group ${className}`}
      data-testid={`statistic-${title}-${value}`}
      onClick={onClick}
    >
      <p className="text-4xl font-bold text-primaryElectricBlue">{value}</p>
      <p className="group-hover:underline">{title}</p>
    </Button>
  );
}

export default Statistic;
