import React from "react";

import { t } from "i18next";

interface ProgressBarProps {
  numberOfTotalInvitations: number;
  numberOfBookedInvitations: number;
  numberOfFollowedUpInvitations: number;
  numberOfNotBookedInvitations: number;
}

export function InvitationStatusStatistics({
  numberOfTotalInvitations,
  numberOfBookedInvitations,
  numberOfFollowedUpInvitations,
  numberOfNotBookedInvitations,
}: ProgressBarProps) {
  const total = numberOfTotalInvitations;
  const bookedPercent = (numberOfBookedInvitations / total) * 100;
  const followedUpPercent = (numberOfFollowedUpInvitations / total) * 100;
  const notBookedPercent = (numberOfNotBookedInvitations / total) * 100;

  return (
    <>
      {/* Progress Bar */}
      <div className="w-full bg-gray-200 rounded-lg overflow-hidden flex mt-10">
        {numberOfBookedInvitations > 0 && (
          <div
            className="bg-progressBarGreen h-8 text-center text-white"
            style={{
              width: `${bookedPercent}%`,
            }}
          />
        )}

        {numberOfFollowedUpInvitations > 0 && (
          <div
            className="bg-progressBarBlue h-8 text-center text-white"
            style={{
              width: `${followedUpPercent}%`,
            }}
          />
        )}
        {numberOfNotBookedInvitations > 0 && (
          <div
            className="bg-progressBarYellow h-8 text-center text-white"
            style={{
              width: `${notBookedPercent}%`,
            }}
          />
        )}
      </div>
      {/* Legend */}
      <div className="flex justify-end mt-10">
        <div className="flex items-center text-sm p-1 m-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-md">
          <div className="bg-progressBarGreen w-3 h-3 mr-2" />
          <p>
            {t("Dashboard.invitation-status.booked")}:{" "}
            {numberOfBookedInvitations} ({bookedPercent.toFixed(1)}%)
          </p>
        </div>
        <div className="flex items-center text-sm p-1 m-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-md">
          <div className="bg-progressBarBlue w-3 h-3 mr-2" />
          <p>
            {t("Dashboard.invitation-status.followed-up")}:{" "}
            {numberOfFollowedUpInvitations} ({followedUpPercent.toFixed(1)}%)
          </p>
        </div>
        <div className="flex items-center text-sm p-1 m-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-md">
          <div className="bg-progressBarYellow w-3 h-3 mr-2" />
          <p>
            {t("Dashboard.invitation-status.non-booked")}:{" "}
            {numberOfNotBookedInvitations} ({notBookedPercent.toFixed(1)}%)
          </p>
        </div>
      </div>
    </>
  );
}
