import { GenericAbortSignal } from "axios";

import { OpeningDay } from "@models/Showroom";
import { Organization } from "@models/old/Organization";
import { SalesCampaign } from "@models/old/SalesCampaign";
import { SellerWithAvailabilityStatus } from "@models/old/Seller";
import Showroom from "@models/old/Showroom";
import { AppointmentFormat, AppointmentTypeEnum } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { OrganizationAPIPaths } from "@services/api/old/organization/OrganizationAPI";

import salesCampaignMapper from "./SalesCampaignMapper";
import { serializeShowroom, unserializeShowroom } from "./ShowroomMapper";

export interface ShowroomCreateAPIData {
  name: string;
  timezone: string;
  addressComponents?: string;
  directions: string | null;
  lunchBreakStartingHour: Date | null;
  lunchBreakEndingHour: Date | null;
  openingHour: Date;
  closingHour: Date;
  customOpeningHoursByDay: Record<
    string,
    { openingHour: Date | null; closingHour: Date | null }
  > | null;
  openingDays: OpeningDay[];
  collections: { id: string }[];
  appointmentFormats: AppointmentFormat[];
  appointmentTypes: AppointmentTypeEnum[];
  specialRequestsAllowed: boolean;
  appointmentTypesDuration: (
    | { type: AppointmentTypeEnum.WALKTHROUGH; duration: number }
    | {
        type: AppointmentTypeEnum.BUYING_APPOINTMENT;
        collectionId: string;
        duration: number;
      }
  )[];
  appointmentIncrement: number | null;
  sellers: { id: string; appointmentTypes: AppointmentTypeEnum[] }[];
  lastAllowedBookingDate: Date | null;
  numberOfSeats: number | null;
}

export const SalesCampaignAPIPaths = {
  salesCampaign: (
    organizationId: Organization["id"],
    salesCampaignId?: string,
  ) =>
    `${OrganizationAPIPaths.getOrganization(organizationId)}/sales-campaigns${
      salesCampaignId ? `/${salesCampaignId}` : ""
    }`,
  getSalesCampaignValidation: (
    organizationId: Organization["id"],
    salesCampaignId: string,
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/validate`,
  getShowrooms: (organizationId: Organization["id"], salesCampaignId: string) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/showrooms`,
  getShowroom: (organizationId: Organization["id"], showroomId?: string) =>
    `${OrganizationAPIPaths.getOrganization(organizationId)}/showrooms${
      showroomId ? `/${showroomId}` : ""
    }`,
  sellers: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/sellers`,
  sellersForAppointment: (
    organizationId: Organization["id"],
    showroomId: Showroom["id"],
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/showrooms/${showroomId}/sellers-for-appointment`,
  daily: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    showroomId: Showroom["id"],
    dayAsString: string,
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/showrooms/${showroomId}/calendar/daily/${dayAsString}`,
  activate: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/activate`,
  invitations: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/invitations`,
  followups: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/follow-ups`,
  deleteAttachment: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    attachmentName: string,
  ) =>
    `${OrganizationAPIPaths.getOrganization(
      organizationId,
    )}/sales-campaigns/${salesCampaignId}/attachments/${attachmentName}`,
};

const SalesCampaignAPI = {
  getSellers: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    signal?: GenericAbortSignal,
  ): Promise<SellerWithAvailabilityStatus[]> => {
    const url = `${SalesCampaignAPIPaths.sellers(
      organizationId,
      salesCampaignId,
    )}`;
    return axiosInstance.get(url, { signal }).then((res) => res.data);
  },

  getSellersForAppointment: (
    organizationId: Organization["id"],
    showroomId: Showroom["id"],
    startTime: Date,
    endTime: Date,
    options: {
      editedAppointmentId?: string;
      accountId?: string;
      collectionId?: string;
      type?: AppointmentTypeEnum;
      format?: AppointmentFormat;
    },
    signal?: GenericAbortSignal,
  ): Promise<SellerWithAvailabilityStatus[]> => {
    const searchParams = new URLSearchParams();

    if (startTime) {
      searchParams.set("startTime", startTime.toISOString());
    }
    if (endTime) {
      searchParams.set("endTime", endTime.toISOString());
    }

    if (options.editedAppointmentId) {
      searchParams.set("editedAppointmentId", options.editedAppointmentId);
    }
    if (options.accountId) {
      searchParams.set("accountId", options.accountId);
    }
    if (options.type) {
      searchParams.set("type", options.type);
    }
    if (options.collectionId) {
      searchParams.set("collectionId", options.collectionId);
    }
    if (options.format) {
      searchParams.set("format", options.format);
    }

    const url = `${SalesCampaignAPIPaths.sellersForAppointment(
      organizationId,
      showroomId,
    )}?${searchParams.toString()}`;
    return axiosInstance.get(url, { signal }).then((res) => res.data);
  },

  getSalesCampaign: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) =>
    axiosInstance
      .get(SalesCampaignAPIPaths.salesCampaign(organizationId, salesCampaignId))
      .then((res) => salesCampaignMapper(res.data) as SalesCampaign),

  getSalesCampaignValidation: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) =>
    axiosInstance
      .get(
        SalesCampaignAPIPaths.getSalesCampaignValidation(
          organizationId,
          salesCampaignId,
        ),
      )
      .then((res) => res.data),

  createSalesCampaign: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    name: string,
    files: File[],
    attachments?: Record<string, string>,
  ): Promise<SalesCampaign> => {
    const formData = new FormData();
    formData.append("organizationId", organizationId);
    formData.append("name", name);
    formData.append("id", salesCampaignId);
    formData.append("attachments", JSON.stringify(attachments));
    files.forEach((file) => formData.append("files", file));
    return axiosInstance
      .post(SalesCampaignAPIPaths.salesCampaign(organizationId), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },

  activateSalesCampaign: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ): Promise<void> =>
    axiosInstance.post(
      SalesCampaignAPIPaths.activate(organizationId, salesCampaignId),
    ),

  deleteSalesCampaign: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ): Promise<void> =>
    axiosInstance.delete(
      SalesCampaignAPIPaths.salesCampaign(organizationId, salesCampaignId),
    ),

  deleteAttachment: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    attachmentName: string,
  ): Promise<void> =>
    axiosInstance.delete(
      SalesCampaignAPIPaths.deleteAttachment(
        organizationId,
        salesCampaignId,
        attachmentName,
      ),
    ),

  getShowrooms: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ): Promise<Showroom[]> =>
    axiosInstance
      .get(SalesCampaignAPIPaths.getShowrooms(organizationId, salesCampaignId))
      .then((res) => res.data.map(unserializeShowroom)),

  createShowroom: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    showroomData: ShowroomCreateAPIData,
  ) =>
    axiosInstance.post(
      SalesCampaignAPIPaths.getShowroom(organizationId, salesCampaignId),
      serializeShowroom({
        salesCampaignId,
        ...showroomData,
      }),
    ),
  getShowroom: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    showroomId: Showroom["id"],
  ): Promise<Showroom> =>
    axiosInstance
      .get(SalesCampaignAPIPaths.getShowroom(organizationId, showroomId))
      .then((response) => unserializeShowroom(response.data)),
  updateShowroom: (
    organizationId: Organization["id"],
    showroomId: Showroom["id"],
    showroomData: ShowroomCreateAPIData,
  ) => {
    const data = serializeShowroom({
      id: showroomId,
      ...showroomData,
    });
    return axiosInstance.put(
      SalesCampaignAPIPaths.getShowroom(organizationId, showroomId),
      data,
    );
  },

  deleteShowroom: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    showroomId: Showroom["id"],
  ) =>
    axiosInstance.delete(
      SalesCampaignAPIPaths.getShowroom(organizationId, showroomId),
    ),

  sendInvites: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    organizationContactIds: string[],
  ) =>
    axiosInstance.post(
      SalesCampaignAPIPaths.invitations(organizationId, salesCampaignId),
      {
        organizationContactIds,
      },
    ),

  sendFollowUps: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    organizationContactIds: string[],
  ) =>
    axiosInstance.post(
      SalesCampaignAPIPaths.followups(organizationId, salesCampaignId),
      {
        organizationContactIds,
      },
    ),
};

export const SalesCampaignQueries = {
  getSalesCampaign: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) => ({
    queryKey: ["sales-campaigns", salesCampaignId],
    queryFn: async () =>
      SalesCampaignAPI.getSalesCampaign(organizationId, salesCampaignId),
  }),
  getSalesCampaignValidation: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) => ({
    queryKey: ["sales-campaigns", salesCampaignId, "validate"],
    queryFn: async () =>
      SalesCampaignAPI.getSalesCampaignValidation(
        organizationId,
        salesCampaignId,
      ),
  }),
  getShowroom: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    showroomId: Showroom["id"],
  ) => ({
    queryKey: ["sales-campaigns", salesCampaignId, "showrooms", showroomId],
    queryFn: () =>
      SalesCampaignAPI.getShowroom(organizationId, salesCampaignId, showroomId),
  }),
  getShowrooms: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) => ({
    queryKey: ["sales-campaigns", salesCampaignId, "showrooms"],
    queryFn: () =>
      SalesCampaignAPI.getShowrooms(organizationId, salesCampaignId),
  }),
  getSellers: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
    signal?: GenericAbortSignal,
  ) => ({
    queryKey: ["sales-campaigns", "sellers", salesCampaignId],
    queryFn: () =>
      SalesCampaignAPI.getSellers(organizationId, salesCampaignId, signal),
  }),
  getSellersForAppointment: (
    organizationId: Organization["id"],
    showroomId: Showroom["id"],
    startTime: Date,
    endTime: Date,
    options: {
      format?: AppointmentFormat;
      type?: AppointmentTypeEnum;
      accountId?: string;
      collectionId?: string;
      editedAppointmentId?: string;
    },
    signal?: GenericAbortSignal,
  ) => ({
    queryKey: [
      "showrooms",
      showroomId,
      "sellers",
      startTime.toISOString(),
      endTime.toISOString(),
    ]
      .concat(options.editedAppointmentId ? [options.editedAppointmentId] : [])
      .concat(options.accountId ? [options.accountId] : [])
      .concat(options.format ? [options.format] : [])
      .concat(options.collectionId ? [options.collectionId] : [])
      .concat(options.type ? [options.type] : []),
    cacheTime: 0,
    queryFn: () =>
      SalesCampaignAPI.getSellersForAppointment(
        organizationId,
        showroomId,
        startTime,
        endTime,
        options,
        signal,
      ),
  }),
};

export interface UpdateShowroomMutationProps {
  organizationId: Organization["id"];
  showroomId: string;
  showroomData: ShowroomCreateAPIData;
}

export const SalesCampaignMutations = {
  createSalesCampaign: (organizationId: string) => ({
    mutationFn: (formData: any) =>
      SalesCampaignAPI.createSalesCampaign(
        organizationId,
        formData.salesCampaignId,
        formData.name,
        formData.files,
        formData.attachments,
      ),
  }),
  activateSalesCampaign: () => ({
    mutationFn: ({
      organizationId,
      salesCampaignId,
    }: {
      organizationId: Organization["id"];
      salesCampaignId: Organization["id"];
    }) =>
      SalesCampaignAPI.activateSalesCampaign(organizationId, salesCampaignId),
  }),
  deleteSalesCampaign: () => ({
    mutationFn: ({
      organizationId,
      salesCampaignId,
    }: {
      organizationId: Organization["id"];
      salesCampaignId: Organization["id"];
    }) => SalesCampaignAPI.deleteSalesCampaign(organizationId, salesCampaignId),
  }),
  deleteAttachment: (
    organizationId: Organization["id"],
    salesCampaignId: SalesCampaign["id"],
  ) => ({
    mutationFn: ({ attachmentName }: { attachmentName: string }) =>
      SalesCampaignAPI.deleteAttachment(
        organizationId,
        salesCampaignId,
        attachmentName,
      ),
  }),
  upsertShowroom: () => ({
    mutationFn: ({
      organizationId,
      salesCampaignId,
      showroomId,
      showroomData,
    }: {
      organizationId: Organization["id"];
      salesCampaignId: string;
      showroomId: string | undefined;
      showroomData: ShowroomCreateAPIData;
    }) =>
      showroomId
        ? SalesCampaignAPI.updateShowroom(
            organizationId,
            showroomId,
            showroomData,
          )
        : SalesCampaignAPI.createShowroom(
            organizationId,
            salesCampaignId,
            showroomData,
          ),
  }),
  updateShowroom: () => ({
    mutationFn: ({
      organizationId,
      showroomId,
      showroomData,
    }: UpdateShowroomMutationProps) =>
      SalesCampaignAPI.updateShowroom(organizationId, showroomId, showroomData),
  }),
  // why not used ?
  createShowroom: () => ({
    mutationFn: ({
      organizationId,
      salesCampaignId,
      showroomData,
    }: {
      organizationId: Organization["id"];
      salesCampaignId: string;
      showroomData: ShowroomCreateAPIData;
    }) =>
      SalesCampaignAPI.createShowroom(
        organizationId,
        salesCampaignId,
        showroomData,
      ),
  }),
  deleteShowroom: () => ({
    mutationFn: ({
      organizationId,
      salesCampaignId,
      showroomId,
    }: {
      organizationId: Organization["id"];
      salesCampaignId: string;
      showroomId: string;
    }) =>
      SalesCampaignAPI.deleteShowroom(
        organizationId,
        salesCampaignId,
        showroomId,
      ),
  }),
  sendInvites: () => ({
    mutationFn: ({
      organizationId,
      salesCampaignId,
      organizationContactIds,
    }: {
      organizationId: Organization["id"];
      salesCampaignId: string;
      organizationContactIds: string[];
    }) =>
      SalesCampaignAPI.sendInvites(
        organizationId,
        salesCampaignId,
        organizationContactIds,
      ),
  }),
  sendFollowUps: () => ({
    mutationFn: ({
      organizationId,
      salesCampaignId,
      organizationContactIds,
    }: {
      organizationId: Organization["id"];
      salesCampaignId: string;
      organizationContactIds: string[];
    }) =>
      SalesCampaignAPI.sendFollowUps(
        organizationId,
        salesCampaignId,
        organizationContactIds,
      ),
  }),
};

export default SalesCampaignAPI;
