import { DragEndEvent } from "@dnd-kit/core";
import {
  addMinutes,
  areIntervalsOverlapping,
  differenceInMinutes,
} from "date-fns";

import {
  AccountAppointmentFormDataSubmitted,
  appointmentToFormData,
} from "@calendar/components/appointment/dialog-edit";
import { BusyAppointmentEditForm } from "@calendar/components/busy/edit-form";
import { Calendar } from "@calendar/types";

export default class CalendarHelper {
  public static toDroppableId(sellerId: string, time: Date) {
    return `${sellerId}|${time.toISOString()}`;
  }

  public static fromDroppableId(droppableId: string) {
    const [sellerId, time] = droppableId.split("|");
    return {
      sellerId,
      time: new Date(time),
    };
  }

  static createFormDataFromDragEndEvent(
    ev: DragEndEvent,
    dailyCalendar: Calendar.DailyCalendar,
  ):
    | AccountAppointmentFormDataSubmitted
    | BusyAppointmentEditForm.FormDataValidated
    | undefined {
    const { active, over } = ev;
    if (!over) return undefined;
    // find infos from drop zone & draggable
    const { sellerId: targetSellerId, time } = CalendarHelper.fromDroppableId(
      over.id.toString(),
    );
    const appointmentIdBeingDropped = active.id.toString();

    // find corresponding appointment in calendar
    const appointment = CalendarHelper.retrieveAppointment(
      dailyCalendar,
      appointmentIdBeingDropped,
    );
    if (!appointment) return undefined;

    // find corresponding seller agenda
    const seller = dailyCalendar.find(
      (s: { id: string }) => s.id === targetSellerId,
    );
    if (!seller) return undefined;

    // compute new data
    const newData = {
      sellerId: targetSellerId,
      startTime: time,
      endTime: addMinutes(
        time,
        differenceInMinutes(
          // here we use the real dates because we are calculating a difference, so timezone does not matter
          appointment.endTime.realDate,
          appointment.startTime.realDate,
        ),
      ),
    };

    // check if conflicting with other appointments
    const isConflicting = CalendarHelper.isConflictingWithOtherAppointments(
      appointmentIdBeingDropped,
      seller.appointments,
      newData.startTime,
      newData.endTime,
    );
    if (isConflicting) return undefined;

    // only startTime / endTime / sellerId can be changed from drag'n'drop
    const formData = Calendar.checkIsBusyAppointment(appointment)
      ? BusyAppointmentEditForm.toFormData(appointment)
      : appointmentToFormData(appointment);

    return {
      ...formData,
      sellerId: newData.sellerId,
      startTime: newData.startTime,
      endTime: newData.endTime,
    };
  }

  static retrieveAppointment(
    dailyCalendar: Calendar.DailyCalendar,
    appointmentId: string,
  ): Calendar.Appointment | undefined {
    let appointment;
    dailyCalendar.forEach((seller) => {
      seller.appointments.forEach((a) => {
        if (a.id === appointmentId) {
          appointment = a;
        }
      });
    });
    return appointment;
  }

  private static isConflictingWithOtherAppointments(
    appointmentId: string,
    appointments: Calendar.SellerAgenda["appointments"],
    startTime: Date,
    endTime: Date,
  ): boolean {
    const conflicting = appointments
      .filter((appointment) => appointment.id !== appointmentId)
      .filter((appointment) =>
        areIntervalsOverlapping(
          { start: startTime, end: endTime },
          // here we compare the local dates because that's what we get from the UI
          {
            start: appointment.startTime.toLocalDate(
              appointment.showroom.timezone,
            ),
            end: appointment.endTime.toLocalDate(appointment.showroom.timezone),
          },
        ),
      );
    return conflicting.length > 0;
  }
}
