import React from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { placeholderData } from "./chart-data";
import EmptyChartTooltip from "./empty-chart-tooltip";

function BarChartPlaceholder() {
  return (
    <ResponsiveContainer width="100%" height={180 + 23} debounce={50}>
      <BarChart
        data={placeholderData}
        layout="vertical"
        margin={{ right: 100, top: 40 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis axisLine={false} type="number" domain={[0, 21]} hide />
        <YAxis
          yAxisId={0}
          type="category"
          axisLine={false}
          dataKey="name"
          tick={{
            transform: "translate(-24, 0)",
            fontSize: 16,
            textAnchor: "end",
          }}
        />
        <YAxis
          yAxisId={1}
          orientation="right"
          type="category"
          axisLine={false}
          dataKey="name"
          mirror
          tick={{
            transform: "translate(24, 0)",
            fontSize: 16,
            textAnchor: "start",
          }}
        />
        <Tooltip cursor={{ fill: "#FAFAFA" }} content={<EmptyChartTooltip />} />
        <Bar minPointSize={2} barSize={40} dataKey="value">
          {placeholderData.map((d) => (
            <Cell key={d.name} fill="#F0F0F0" />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartPlaceholder;
