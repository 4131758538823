import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { HiOutlineTrash } from "react-icons/hi";
import { toast } from "react-toastify";

import Button from "@components/data-entry/Button";
import { ZonedDate, formatDatetimeIntervalAtTimezone } from "@helpers/Date";
import { BookingMutations } from "@services/api/old/booking/BookingAPI";
import fetchBookingAppointmentsEndpoint from "@services/api/old/booking/fetch-appointments";

interface BookedTimeSlotProps {
  appointment: {
    id: string;
    startTime: ZonedDate;
    endTime: ZonedDate;
  };
  timezone: string;
  invitationId: string;
  onDelete?: () => void;
}

export default function BookedTimeSlot({
  appointment: { id: appointmentId, startTime, endTime },
  timezone,
  invitationId,
  onDelete = () => {},
}: BookedTimeSlotProps) {
  const isCancelling = false;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync: deleteAppointmentMutation, isPending: isDeleteLoading } =
    useMutation({
      ...BookingMutations.deleteAppointment(),
      onSuccess: () => {
        fetchBookingAppointmentsEndpoint.invalidate(queryClient, invitationId);
        toast.success(t("Toast.appointment-deleted"));
        onDelete();
      },
    });

  return (
    <div
      role="group"
      aria-label={formatDatetimeIntervalAtTimezone(
        startTime,
        endTime,
        timezone,
      )}
      className="relative flex items-center justify-center py-2 px-4 mb-2 transition-colors duration-200 min-w-[16rem] disabled:opacity-50 w-full rounded-full bg-statusGreenLight text-primaryBlack border border-statusGreenDark"
    >
      <span className="flex flex-col grow items-center justify-center">
        <span className="flex gap-2 items-center">
          <i className="w-2 h-2 rounded-full bg-statusGreenDark" />
          <span>
            {formatDatetimeIntervalAtTimezone(startTime, endTime, timezone)}
          </span>
        </span>
        <span>{timezone}</span>
      </span>

      <Button
        className="place-end justify-center text-statusRedDark"
        loading={isCancelling}
        theme="ICON"
        rounded="full"
        disabled={isDeleteLoading}
        onClick={() =>
          deleteAppointmentMutation({ invitationId, appointmentId })
        }
        type="button"
      >
        <HiOutlineTrash />
      </Button>
    </div>
  );
}
