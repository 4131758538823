import { z } from "zod";

export const organizationStatisticsSchema = z.object({
  activeAccountsCount: z.number(),
  prospectAccountsCount: z.number(),
  contactsCount: z.number(),
  representativesCount: z.number(),
});

export type OrganizationStatistics = z.infer<
  typeof organizationStatisticsSchema
>;
