import * as React from "react";
import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useBookingContext } from "@booking/components/hook";
import SlotSelect from "@booking/components/slot-select";
import Loading from "@components/feedback/Loading";
import { Showroom } from "@models/Showroom";
import { AppointmentTypeEnum } from "@models/types/enums";
import { BookingGetBookedAppointmentsEndpoint } from "@services/api/booking/get-booked-appointments";
import { BookingGetInvitationInfoEndpoint } from "@services/api/booking/get-invitation-infos";

export default function AppointmentEditPage() {
  const { invitationId, appointmentId } = useParams();
  const { dispatch } = useBookingContext();

  if (!invitationId || !appointmentId) {
    throw new Error("missing parameters");
  }

  const navigate = useNavigate();

  const {
    data: invitationData,
    isError,
    error,
    isPending,
  } = BookingGetInvitationInfoEndpoint.useHook({ invitationId });

  const {
    data: appointments = [],
    isPending: isLoadingAppointments,
    isError: isErrorAppointments,
    error: errorAppointments,
  } = BookingGetBookedAppointmentsEndpoint.useHook({ invitationId });

  useEffect(() => {
    const appointment = appointments.find((appt) => appt.id === appointmentId);
    if (!appointment) {
      throw new Error("Appointment is not editable");
    }
    // once the appointment is found, fill the state with the appointment data
    if (
      appointment.type === AppointmentTypeEnum.BUYING_APPOINTMENT &&
      appointment.collection
    ) {
      dispatch({
        type: "TOGGLE_BUYING",
        showroomId: appointment.showroom.id,
        collections: [appointment.collection],
      });
    } else if (appointment.type === AppointmentTypeEnum.WALKTHROUGH) {
      dispatch({
        type: "TOGGLE_WALKTHROUGH",
        showroomId: appointment.showroom.id,
        collectionInterests: [],
      });
    }
    dispatch({
      type: "BOOKED_APPOINTMENT",
      appointment,
      index: 0,
    });
  }, [appointmentId, appointments]);

  if (isPending || isLoadingAppointments) {
    return <Loading type="screen" />;
  }

  if (isError) {
    throw error;
  }
  if (isErrorAppointments) {
    throw errorAppointments;
  }

  const { showrooms } = invitationData;

  const appointment = appointments.find((appt) => appt.id === appointmentId);
  const showroom = showrooms.find(
    (s: Showroom) => s.id === appointment?.showroom.id,
  );

  if (!appointment || !showroom) {
    throw new Error("Appointment is not editable");
  }

  const goToDashboard = () => navigate(`/booking/${invitationId}`);

  return (
    <SlotSelect
      editedAppointmentId={appointment.id}
      invitationId={invitationId}
      onPrevious={goToDashboard}
      onSubmit={goToDashboard}
      organization={invitationData.organization}
      contact={invitationData.contact}
      showrooms={invitationData.showrooms}
      portfolios={invitationData.portfolios}
    />
  );
}
