import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from "react";

import { Appointment } from "@models/Appointment";
import { Collection } from "@models/Collection";
import { Contact } from "@models/Contact";
import { Showroom } from "@models/Showroom";
import { AppointmentFormat, AppointmentTypeEnum } from "@models/types/enums";
import { BookingUpsertSpecialRequestEndpoint } from "@services/api/booking/upsert-special-request";

type Attendee = Pick<Contact, "id" | "firstName" | "lastName">;
type AppointmentCollection = Pick<Collection, "id" | "name">;
type AppointmentBooked = Pick<
  Appointment,
  | "id"
  | "startTime"
  | "endTime"
  | "type"
  | "virtualMeetingApp"
  | "format"
  | "accountOtb"
  | "warnings"
> & {
  showroom: Pick<Showroom, "id">;
  collection: Pick<Collection, "id"> | null;
};
type SpecialRequestBooked = BookingUpsertSpecialRequestEndpoint.Output;

/** ACTIONS */

// when toggling "buying appointment"
type TogglingBuyingAppointment = {
  type: "TOGGLE_BUYING";
  showroomId: string;
  collections: AppointmentCollection[];
};

// when toggling "walkthrough"
type TogglingWalkthrough = {
  type: "TOGGLE_WALKTHROUGH";
  showroomId: string;
  collectionInterests: AppointmentCollection[];
};

// when (de)selecting collections to buy
type AddCollectionToBuy = {
  type: "ADD_COLLECTION";
  showroomId: string;
  collection: AppointmentCollection;
  appointmentType: AppointmentTypeEnum.BUYING_APPOINTMENT;
};
type RemoveCollectionToBuy = {
  type: "REMOVE_COLLECTION";
  showroomId: string;
  collection: AppointmentCollection;
};
type SetCollectionsToBuy = {
  type: "SET_COLLECTIONS_TO_BUY";
  showroomId: string;
  collections: AppointmentCollection[];
};

type SetcollectionInterestsToWalkthrough = {
  type: "SET_INTERESTING_COLLECTIONS_TO_WALKTHROUGH";
  showroomId: string;
  collections: AppointmentCollection[];
};

// either a buying appointment, or a walkthrough appointment
interface BookingAppointment {
  type: AppointmentTypeEnum;
  showroomId: string;
  attendees: Attendee[];
  language: string | null;
  format: AppointmentFormat | null;
  bookedAppointment: AppointmentBooked | null;
  specialRequest: SpecialRequestBooked | null;
  cancelled: boolean;
  collectionInterests: AppointmentCollection[];
}

interface BuyingAppointment extends BookingAppointment {
  type: AppointmentTypeEnum.BUYING_APPOINTMENT;
  collection: AppointmentCollection;
}

interface WalkthroughAppointment extends BookingAppointment {
  type: AppointmentTypeEnum.WALKTHROUGH;
}

type SetAppointmentAttendees = {
  type: "SET_APPOINTMENT_ATTENDEES";
  showroomId: string;
  appointmentType: AppointmentTypeEnum;
  attendees: Attendee[];
  collectionId?: string;
};

type BookedAppointment = {
  type: "BOOKED_APPOINTMENT";
  index: number;
  appointment: AppointmentBooked;
};
type BookedSpecialRequest = {
  type: "BOOKED_SPECIAL_REQUEST";
  index: number;
  specialRequest: SpecialRequestBooked;
};
type CancelAppointment = {
  type: "CANCEL_APPOINTMENT";
  index: number;
};
type DeleteBookedAppointment = {
  type: "DELETE_BOOKED_APPOINTMENT";
  index: number;
};

type BookingAction =
  | TogglingBuyingAppointment
  | TogglingWalkthrough
  | AddCollectionToBuy
  | RemoveCollectionToBuy
  | SetAppointmentAttendees
  | BookedAppointment
  | BookedSpecialRequest
  | CancelAppointment
  | SetCollectionsToBuy
  | DeleteBookedAppointment
  | SetcollectionInterestsToWalkthrough;

/** STATE */
interface BookingState {
  appointments: Array<BuyingAppointment | WalkthroughAppointment>;
}

/** SELECTORS */
export function selectShowroomAppointmentTypes(state: BookingState) {
  return (showroomId: string): AppointmentTypeEnum[] =>
    state.appointments
      .filter((appt) => appt.showroomId === showroomId)
      .map((appt) => appt.type);
}

export function selectAppointmentForCollection(state: BookingState) {
  return (collectionId: string, showroomId: string) =>
    state.appointments.find(
      (c) =>
        "collection" in c &&
        collectionId === c.collection.id &&
        c.showroomId === showroomId,
    );
}

export function hasAppointmentForCollection(state: BookingState) {
  return (collectionId: string, showroomId: string) =>
    selectAppointmentForCollection(state)(collectionId, showroomId) !==
    undefined;
}

export function selectSpecialRequests(state: BookingState) {
  return () =>
    state.appointments
      .map((appt) => appt.specialRequest)
      .filter((appt) => appt !== null) as SpecialRequestBooked[];
}

export function selectBookedAppointments(state: BookingState) {
  return () =>
    state.appointments
      .map((appt) => appt.bookedAppointment)
      .filter((appt) => appt !== null) as AppointmentBooked[];
}

export function selectCollectionsSelected(state: BookingState) {
  return (showroomId: string) =>
    state.appointments
      .filter((appt) => appt.showroomId === showroomId)
      .map((appt) => ("collection" in appt ? appt.collection : null))
      .filter((c) => c !== null) as AppointmentCollection[];
}

export function selectWalkthroughCollectionInterests(state: BookingState) {
  return (showroomId: string) =>
    state.appointments.find(
      (appt) =>
        appt.type === AppointmentTypeEnum.WALKTHROUGH &&
        appt.collectionInterests &&
        appt.showroomId === showroomId,
    );
}

export function hasWalkthroughInterestInCollection(state: BookingState) {
  return (showroomId: string, collectionId: string) =>
    selectWalkthroughCollectionInterests(state)(
      showroomId,
    )?.collectionInterests.some((c) => c.id === collectionId) !== undefined;
}

/** REDUCER */
function bookingReducer(
  prevState: BookingState,
  action: BookingAction,
  buyer: Attendee,
): BookingState {
  let newState: BookingState = { ...prevState };
  switch (action.type) {
    case "TOGGLE_BUYING":
      newState = {
        ...prevState,
        appointments: selectShowroomAppointmentTypes(prevState)(
          action.showroomId,
        ).includes(AppointmentTypeEnum.BUYING_APPOINTMENT)
          ? prevState.appointments.filter(
              (appt) =>
                appt.showroomId !== action.showroomId ||
                appt.type !== AppointmentTypeEnum.BUYING_APPOINTMENT,
            )
          : prevState.appointments.concat(
              action.collections.map((c) => ({
                attendees: [buyer],
                collection: c,
                format: null,
                language: null,
                showroomId: action.showroomId,
                type: AppointmentTypeEnum.BUYING_APPOINTMENT,
                bookedAppointment: null,
                specialRequest: null,
                cancelled: false,
                collectionInterests: [],
              })),
            ),
      };
      break;
    case "TOGGLE_WALKTHROUGH":
      newState = {
        ...prevState,
        appointments: selectShowroomAppointmentTypes(prevState)(
          action.showroomId,
        ).includes(AppointmentTypeEnum.WALKTHROUGH)
          ? prevState.appointments.filter(
              (appt) =>
                appt.showroomId !== action.showroomId ||
                appt.type !== AppointmentTypeEnum.WALKTHROUGH,
            )
          : prevState.appointments.concat([
              {
                attendees: [buyer],
                type: AppointmentTypeEnum.WALKTHROUGH,
                format: null,
                language: null,
                showroomId: action.showroomId,
                bookedAppointment: null,
                specialRequest: null,
                cancelled: false,
                collectionInterests: action.collectionInterests,
              },
            ]),
      };
      break;
    case "ADD_COLLECTION":
      newState = {
        ...prevState,
        appointments: prevState.appointments.concat([
          {
            type: action.appointmentType,
            attendees: [buyer],
            collection: action.collection,
            showroomId: action.showroomId,
            format: null,
            language: null,
            bookedAppointment: null,
            specialRequest: null,
            cancelled: false,
            collectionInterests: [],
          },
        ]),
      };
      break;
    case "REMOVE_COLLECTION":
      newState = {
        ...prevState,
        appointments: prevState.appointments.filter(
          (appt) =>
            !("collection" in appt) ||
            appt.collection.id !== action.collection.id,
        ),
      };
      break;
    case "SET_COLLECTIONS_TO_BUY":
      newState = {
        ...prevState,
        appointments: prevState.appointments
          .filter(
            (appt) =>
              appt.showroomId !== action.showroomId ||
              appt.type !== AppointmentTypeEnum.BUYING_APPOINTMENT,
          )
          .concat(
            action.collections.map((c) => ({
              type: AppointmentTypeEnum.BUYING_APPOINTMENT,
              attendees: [buyer],
              collection: c,
              showroomId: action.showroomId,
              format: null,
              language: null,
              bookedAppointment: null,
              specialRequest: null,
              cancelled: false,
              collectionInterests: [],
            })),
          ),
      };
      break;
    case "SET_APPOINTMENT_ATTENDEES":
      newState = {
        ...prevState,
        appointments: prevState.appointments.map((appt) => {
          if (
            // match the showroom AND match either the collection or the type
            appt.showroomId === action.showroomId &&
            appt.type === action.appointmentType &&
            "collection" in appt
              ? appt.collection.id === action.collectionId
              : true
          ) {
            return {
              ...appt,
              attendees: action.attendees,
            };
          }
          return appt;
        }),
      };
      break;
    case "BOOKED_APPOINTMENT":
      newState.appointments[action.index].cancelled = false;
      newState.appointments[action.index].bookedAppointment =
        action.appointment;
      break;
    case "BOOKED_SPECIAL_REQUEST":
      newState.appointments[action.index].specialRequest =
        action.specialRequest;
      break;
    case "CANCEL_APPOINTMENT":
      newState.appointments[action.index].cancelled = true;
      break;
    case "DELETE_BOOKED_APPOINTMENT":
      newState.appointments[action.index].bookedAppointment = null;
      break;
    case "SET_INTERESTING_COLLECTIONS_TO_WALKTHROUGH":
      newState.appointments = prevState.appointments.map((appt) =>
        appt.showroomId === action.showroomId &&
        appt.type === AppointmentTypeEnum.WALKTHROUGH
          ? { ...appt, collectionInterests: action.collections }
          : appt,
      );
      break;
    default:
      return prevState;
  }
  return newState;
}

/**
 * HOOK & CONTEXT PROVIDER
 */
export function useBookingState(attendee: Attendee) {
  const [state, dispatch] = useReducer(
    (prevState: BookingState, action: BookingAction) => {
      const s = bookingReducer(prevState, action, attendee);
      return s;
    },
    { appointments: [] },
  );
  return useMemo(
    () => ({
      state,
      dispatch,
      selectBookedAppointments: selectBookedAppointments(state),
      selectSpecialRequests: selectSpecialRequests(state),
      selectShowroomAppointmentTypes: selectShowroomAppointmentTypes(state),
      hasAppointmentForCollection: hasAppointmentForCollection(state),
      selectAppointmentForCollection: selectAppointmentForCollection(state),
      selectCollectionsSelected: selectCollectionsSelected(state),
      hasWalkthroughInterestInCollection:
        hasWalkthroughInterestInCollection(state),
      selectWalkthroughCollectionInterests:
        selectWalkthroughCollectionInterests(state),
    }),
    [state, dispatch],
  );
}

const BookingContext = createContext<ReturnType<typeof useBookingState>>({
  dispatch: () => {},
  hasAppointmentForCollection: () => false,
  state: { appointments: [] },
  selectBookedAppointments: () => [],
  selectSpecialRequests: () => [],
  selectShowroomAppointmentTypes: () => [],
  selectAppointmentForCollection: () => undefined,
  selectCollectionsSelected: () => [],
  hasWalkthroughInterestInCollection: () => false,
  selectWalkthroughCollectionInterests: () => undefined,
});

export function useBookingContext() {
  return useContext(BookingContext);
}
export function BookingContextProvider({
  defaultAttendee,
  children,
}: PropsWithChildren<{ defaultAttendee: Attendee }>) {
  const b = useBookingState(defaultAttendee);
  return (
    <BookingContext.Provider value={b}>{children}</BookingContext.Provider>
  );
}
