/* eslint-disable react/button-has-type */
import React, { forwardRef } from "react";

import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import WithConditionalRendering, {
  ConditionalRenderingProps,
} from "@components/high-order-components/conditional-render";
import {
  AddedAccessControlProps,
  addAccessControlProps,
} from "@shared/components/access-control";

import Loading from "../feedback/Loading";

interface ButtonProps {
  disabled?: JSX.IntrinsicElements["button"]["disabled"];
  type?: JSX.IntrinsicElements["button"]["type"];
  style?: JSX.IntrinsicElements["button"]["style"];
  theme?:
    | "PRIMARY"
    | "SECONDARY"
    | "TERTIARY"
    | "LINK"
    | "SUCCESS"
    | "DANGER"
    | "ICON"
    | "NONE";
  rounded?: "full" | "small" | "medium" | "large";
  loading?: boolean;
  testId?: string;
  label?: string;
  title?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  children: React.ReactNode;
  buttonRef?: React.LegacyRef<HTMLButtonElement>;
  name?: string;
  size?: "small" | "medium" | "large";
  justify?: "start" | "end" | "center" | "between";
}

export const buttonStyle = cva(
  "flex items-center gap-2 shadow transition-all duration-200 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed",
  {
    variants: {
      justify: {
        start: "justify-start",
        end: "justify-end",
        center: "justify-center",
        between: "justify-between",
      },
      size: {
        small: "px-2 py-1 gap-1",
        medium: "px-5 py-2",
        large: "px-8 py-3",
      },
      theme: {
        PRIMARY:
          "text-white bg-primaryElectricBlue hover:bg-primaryDarkElectricBlue disabled:bg-primaryElectricBlue",
        SECONDARY:
          "text-primaryElectricBlue bg-white hover:bg-primaryLightElectricBlue border border-primaryElectricBlue",
        TERTIARY:
          "text-primaryDarkGrey bg-transparent border border-primaryDarkGrey",
        SUCCESS: "text-white bg-statusGreenDark",
        DANGER: "text-white bg-statusRedDark",
        LINK: "text-primaryElectricBlue bg-transparent border-0 shadow-none",
        ICON: "bg-transparent border-0 shadow-none p-0",
        NONE: "bg-transparent border-0 shadow-none p-0",
      },
      rounded: {
        small: "rounded-sm",
        medium: "rounded-md",
        large: "rounded-lg",
        full: "rounded-full",
      },
    },
    defaultVariants: {
      size: "medium",
    },
  },
);

function Button({
  theme = "TERTIARY",
  disabled,
  loading,
  type = "submit",
  label,
  testId,
  className,
  name,
  onClick,
  children,
  title,
  rounded = "full",
  style,
  size = "medium",
  buttonRef,
  justify,
}: ButtonProps) {
  return (
    <button
      name={name}
      ref={buttonRef}
      data-testid={testId}
      aria-label={label}
      style={style}
      disabled={disabled || loading}
      onClick={onClick}
      type={type || "button"}
      title={title}
      className={twMerge(
        buttonStyle({ className, theme, size, rounded, justify }),
      )}
    >
      {loading && (
        <>
          <Loading type="button" /> Loading
        </>
      )}
      {!loading && children}
    </button>
  );
}

const ButtonWithHOC = addAccessControlProps(WithConditionalRendering(Button));
export default forwardRef(
  (
    props: ButtonProps &
      ConditionalRenderingProps<ButtonProps> &
      AddedAccessControlProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => <ButtonWithHOC {...props} buttonRef={ref} />,
);
