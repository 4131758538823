import React from "react";

import {
  HiBriefcase,
  HiChevronLeft,
  HiChevronRight,
  HiExclamationTriangle,
  HiMinus,
  HiSwatch,
} from "react-icons/hi2";

import {
  ShowroomFormData,
  useShowroomForm,
  validShowroomFormDataSchema,
} from "@app/modules/showroom/form/hook";
import InputLabel from "@components/data-display/InputLabel";
import Tag from "@components/data-display/Tag";
import TogglePanel from "@components/data-display/TogglePanel";
import Button from "@components/data-entry/Button";
import GroupedSelect from "@components/data-entry/GroupedSelect";
import MultiSelect from "@components/data-entry/MultiSelect";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import CalloutBox from "@components/feedback/CalloutBox";
import LoadingFetch from "@components/feedback/LoadingFetch";
import BottomBar from "@components/layout/BottomBar";
import {
  arrayFilterDuplicateIds,
  arrayFilterIsIdInArray,
} from "@helpers/Array";
import { usePrefixedTranslation } from "@helpers/Translation";
import { Collection } from "@models/Collection";
import { AppointmentTypeEnum, VirtualMeetingApps } from "@models/types/enums";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";
import { GetRepresentativesFromOrganizationEndpoint } from "@services/api/representatives/get-organization-representatives";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import RepresentativeSelect from "@shared/components/representatives-select";
import { ObjectIterator } from "@shared/helpers/object";
import {
  translateAppointmentType,
  translateVirtualApp,
} from "@shared/helpers/translater";

const structureFormSchema = validShowroomFormDataSchema
  .pick({
    collections: true,
    appointmentTypes: true,
    sellers: true,
  })
  .passthrough();

const collectionToOption = (collection: Collection): SelectOptionBase => ({
  label: collection.name,
  value: collection.id,
});

const appointmentTypeToOption = (
  translateFn: any,
  type: AppointmentTypeEnum,
): SelectOptionBase => ({
  label: translateFn(translateAppointmentType(type)),
  value: type,
});

interface Props {
  defaultValues?: Partial<ShowroomFormData>;
  onSubmit: (d: ShowroomFormData) => void;
  onBack: () => void;
}

export default function ShowroomStructureForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const {
    form,
    toggleAppointmentType,
    addSeller,
    removeSeller,
    setSellerAppointmentTypes,
  } = useShowroomForm({ defaultValues, schema: structureFormSchema });
  const { pt, t } = usePrefixedTranslation("Showroom.ShowroomStructureForm");

  const {
    organization: { id },
  } = useOrganizationAppContext();
  const { data: availableCollections = [], status: collectionsFetchStatus } =
    GetOrganizationCollectionsEndpoint.useHook({
      organizationId: id,
    });
  const { data: availableSellers = [], status: sellersFetchStatus } =
    GetRepresentativesFromOrganizationEndpoint.useHook({ organizationId: id });

  const formValues = form.watch();
  const { sellers, appointmentTypes, collections } = formValues;
  const sellerIds = sellers.map((s) => s.id);

  const collectionsIterator = new ObjectIterator(availableCollections);
  const brandsIterator = collectionsIterator
    .mapToIterator("brand")
    .filter((b) => !!b)
    .filter(arrayFilterDuplicateIds);

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <>
      <div className="grow p-4">
        <InputLabel
          error={
            form.formState.errors.collections
              ? pt("select-at-least-one-collection")
              : undefined
          }
        >
          {pt("select-brands-presenting-collections")}
        </InputLabel>
        <div className="grid grid-cols-2 gap-4">
          <LoadingFetch
            status={collectionsFetchStatus}
            error={pt("could-not-fetch-collections")}
          >
            {brandsIterator.getArray().map((brand) => (
              <GroupedSelect
                key={brand.id}
                title={brand.name}
                description={pt("select-presented-collections")}
                options={availableCollections
                  .filter((c) => (c.brand ? c.brand.id === brand.id : false))
                  .map(collectionToOption)}
                selected={collections
                  .filter((c) => (c.brand ? c.brand.id === brand.id : false))
                  .map(collectionToOption)}
                onChange={(collectionOptions) => {
                  const selectedCollections = availableCollections.filter((c) =>
                    collectionOptions.map((o) => o.value).includes(c.id),
                  );
                  form.setValue(
                    "collections",
                    collections
                      .filter((c) => c.brand.id !== brand.id)
                      .concat(selectedCollections),
                  );
                }}
              />
            ))}
          </LoadingFetch>
        </div>
        <InputLabel
          error={
            form.formState.errors.appointmentTypes
              ? pt("select-at-least-one-appointment-type")
              : undefined
          }
        >
          {pt("appointment-types")}
        </InputLabel>
        <div className="grid grid-cols-2 gap-4">
          <TogglePanel
            initiallyOpened={appointmentTypes.includes(
              AppointmentTypeEnum.BUYING_APPOINTMENT,
            )}
            onOn={() =>
              toggleAppointmentType(
                true,
                AppointmentTypeEnum.BUYING_APPOINTMENT,
              )
            }
            onOff={() =>
              toggleAppointmentType(
                false,
                AppointmentTypeEnum.BUYING_APPOINTMENT,
              )
            }
            title={pt("showroom-buying-appointment")}
            id="buying-appointment-toggle"
            icon={<HiBriefcase className="fill-primaryElectricBlue w-6 h-6" />}
          />

          <TogglePanel
            initiallyOpened={appointmentTypes.includes(
              AppointmentTypeEnum.WALKTHROUGH,
            )}
            onOn={() =>
              toggleAppointmentType(true, AppointmentTypeEnum.WALKTHROUGH)
            }
            onOff={() =>
              toggleAppointmentType(false, AppointmentTypeEnum.WALKTHROUGH)
            }
            title={pt("showroom-walkthrough")}
            id="walkthrough-toggle"
            icon={<HiSwatch className="fill-primaryElectricBlue w-6 h-6" />}
          />
        </div>

        <div>
          <InputLabel
            error={
              form.formState.errors.sellers
                ? pt("select-at-least-one-seller")
                : undefined
            }
          >
            {pt("sellers-setup")}
          </InputLabel>
          <LoadingFetch
            status={sellersFetchStatus}
            error={pt("could-not-fetch-sellers")}
          >
            <RepresentativeSelect
              representatives={availableSellers.filter(
                (rep) => !arrayFilterIsIdInArray(sellerIds)(rep),
              )}
              value={undefined}
              onChange={(representative) => {
                if (representative) {
                  addSeller(representative);
                }
              }}
            />

            <div className="mt-4 grid grid-cols-12 gap-4 bg-primaryLightElectricBlue p-4 font-bold capitalize">
              <div />
              <div className="col-span-3">{pt("seller-name")}</div>
              <div className="col-span-5">{pt("seller-appointment-types")}</div>
              <div className="col-span-3">{pt("seller-virtual-tools")}</div>
            </div>
            {sellers.length === 0 && (
              <CalloutBox className="my-4" type="INFORMATIVE">
                {pt("select-sellers-attending-showroom")}
              </CalloutBox>
            )}
            {sellers.map((s, idx) => (
              <div
                key={s.id}
                className="grid grid-cols-12 gap-4 my-2 items-center"
              >
                <div className="flex justify-center">
                  <Button
                    title={pt("remove-seller")}
                    className="p-2"
                    theme="DANGER"
                    onClick={() => removeSeller(s.id)}
                  >
                    <HiMinus className="w-6 h-6" />
                  </Button>
                </div>
                <div className="col-span-3">{s.name}</div>
                <div className="col-span-5">
                  <MultiSelect
                    value={s.appointmentTypes.map((type) =>
                      appointmentTypeToOption(t, type),
                    )}
                    options={appointmentTypes.map((type) => ({
                      label: t(translateAppointmentType(type)),
                      value: type,
                    }))}
                    onChange={(selectedTypes) => {
                      setSellerAppointmentTypes(
                        idx,
                        selectedTypes.map(
                          (o) => o.value as AppointmentTypeEnum,
                        ),
                      );
                    }}
                  />
                </div>
                <div className="col-span-2">
                  {s.virtualMeetingApps && s.virtualMeetingApps.length > 0 ? (
                    s.virtualMeetingApps.map((app) => (
                      <Tag key={app}>
                        {t(translateVirtualApp(app as VirtualMeetingApps))}
                      </Tag>
                    ))
                  ) : (
                    <Tag theme="ORANGE">
                      <HiExclamationTriangle className="fill-statusOrangeDark w-6 h-6" />
                      <span>{pt("no-virtual-tool")}</span>
                    </Tag>
                  )}
                </div>
              </div>
            ))}
          </LoadingFetch>
        </div>
      </div>
      <BottomBar>
        <Button onClick={onBack}>
          <HiChevronLeft />
          {t("Common.previous")}
        </Button>
        <Button theme="PRIMARY" onClick={handleSubmit}>
          {t("Common.next")} <HiChevronRight />
        </Button>
      </BottomBar>
    </>
  );
}
