import React, { useEffect, useState } from "react";

import {
  Row,
  RowData,
  TableMeta,
  TableOptions,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t } from "i18next";
import { mapToObj } from "remeda";
import { twMerge } from "tailwind-merge";

import { PaginationBottomBar } from "@components/data-display/table/pagination";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    cellClassName: string;
    headerClassName?: string;
  }
}

function MRTable<TData extends RowData>({
  noDataLabel,
  cellClassName,
  getRowClassName,
  rowSelectionOption,
  meta,
  displayDataLabel,
  data,
  columns,
  autoResetPageIndex = false,
  initialPageSize = 20,
  initialPageIndex = 0,
  paginationPlacement = "bottom",
}: {
  data: TableOptions<TData>["data"] | undefined;
  columns: TableOptions<TData>["columns"];
  rowSelectionOption?: {
    initialSelection: string[];
    onSelectionChange: (selectedRowIds: string[]) => void;
    canBeSelected: boolean | ((row: Row<TData>) => boolean);
  };
  meta?: TableMeta<TData>;
  noDataLabel?: string;
  cellClassName?: string;
  getRowClassName?: (row: Row<TData>) => string;
  displayDataLabel?: string;
  autoResetPageIndex?: boolean;
  initialPageSize?: number;
  initialPageIndex?: number;
  paginationPlacement?: "top" | "bottom" | "both" | "none";
}) {
  const [pagination, setPagination] = useState({
    pageIndex: initialPageIndex,
    pageSize: initialPageSize,
  });
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>(
    () =>
      mapToObj(rowSelectionOption?.initialSelection || [], (id) => [id, true]),
  );

  useEffect(() => {
    if (rowSelectionOption) {
      rowSelectionOption.onSelectionChange(Object.keys(rowSelection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  // When data changes, the pagination goes to first page in case the number of pages is less than the current page index
  useEffect(() => {
    if (
      data &&
      Math.floor(data.length / pagination.pageSize) < pagination.pageIndex
    ) {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
    }
  }, [data]);

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: rowSelectionOption?.canBeSelected as
      | undefined
      | boolean
      | ((row: any) => boolean),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    getRowId: (row: any) => row.id || row.key,
    meta,
  });

  const rowClassName = (row: Row<TData>): string => {
    let bg = "bg-white";

    if (getRowClassName) {
      bg = getRowClassName(row);
    } else if (row.getIsSelected()) {
      bg = "bg-primaryLightElectricBlue";
    } else if (!row.getCanSelect()) {
      bg = "bg-primaryLightGrey";
    }
    return `${bg} border-b`;
  };

  return (
    <div className={`w-full flex flex-col grow ${cellClassName}`}>
      {["top", "both"].includes(paginationPlacement) && (
        <PaginationBottomBar
          table={table}
          displayDataLabel={displayDataLabel}
        />
      )}
      <div className="overflow-x-auto grow">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={twMerge(
                      `p-2 font-medium ${
                        header.isPlaceholder
                          ? "bg-transparent"
                          : "bg-primaryLightestGrey"
                      } ${header.colSpan > 1 ? "text-center" : "text-left"} ${
                        header.column.columnDef.meta?.headerClassName || ""
                      }`,
                    )}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {table.getRowModel().rows.length > 0 && (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr className={rowClassName(row)} key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={`p-2 ${
                        cell.column.columnDef.meta?.cellClassName || ""
                      }`}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {["bottom", "both"].includes(paginationPlacement) && (
        <PaginationBottomBar
          table={table}
          displayDataLabel={displayDataLabel}
        />
      )}

      {table.getRowModel().rows.length === 0 && (
        <div className="flex justify-center p-5">
          {noDataLabel || t("Common.no-data")}
        </div>
      )}
    </div>
  );
}

export default MRTable;
