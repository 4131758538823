import React, { HTMLProps } from "react";

type Props = {
  htmlFor?: HTMLProps<HTMLLabelElement>["htmlFor"];
  children: React.ReactNode;
  error?: string;
  description?: string;
};

export default function InputLabel({
  children,
  htmlFor,
  error,
  description,
}: Props) {
  return (
    <label className="flex flex-col" htmlFor={htmlFor}>
      <span>
        {children}
        {error && (
          <span className="ml-4 text-xs italic text-primaryRed">{error}</span>
        )}
      </span>
      {description && (
        <span className="inline-block w-full text-xs text-primaryGrey">
          {description}
        </span>
      )}
    </label>
  );
}
