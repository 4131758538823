import React from "react";

import { useTranslation } from "react-i18next";
import { HiUser } from "react-icons/hi2";
import { Outlet } from "react-router-dom";

import { buttonStyle } from "@components/data-entry/Button";
import DropdownMenu from "@components/feedback/DropdownMenu";
import useAuthentication from "@services/authentication/useAuthentication";
import { AuthBuyerOnly } from "@shared/components/guards/authentication";
import { fullName } from "@shared/helpers/formatters";

export default function BuyerLayout() {
  const { currentUser, signOut } = useAuthentication();
  const { t } = useTranslation();

  return (
    <AuthBuyerOnly>
      <div className="w-full h-full flex flex-col">
        <div className="sticky z-header shadow top-0 shrink-0 w-full h-20 bg-primaryLightElectricBlue grid grid-cols-12">
          <div className="col-span-6 flex items-center pl-4 text-3xl">
            <span>
              {t("Common.hi")}{" "}
              <span className="text-primaryElectricBlue font-bold">
                {currentUser ? fullName(currentUser) : "loading"}
              </span>
            </span>
          </div>
          <div className="col-start-12 flex justify-center items-center">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger
                className={buttonStyle({
                  rounded: "full",
                  size: "medium",
                  theme: "TERTIARY",
                })}
              >
                <HiUser />
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content>
                  <DropdownMenu.Item onSelect={signOut}>
                    {t("Common.logout")}
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        </div>
        <div className="grow">
          <Outlet />
        </div>
      </div>
    </AuthBuyerOnly>
  );
}
