import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { organizationStatisticsSchema } from "@models/OrganizationStatistics";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export interface GetOrganizationStatisticsParams {
  organizationId: string;
}

export const getOrganizationStatisticsSchema = organizationStatisticsSchema;

export type GetOrganizationStatisticsResponse = z.infer<
  typeof getOrganizationStatisticsSchema
>;

export const getOrganizationStatisticsEndpoint = {
  path: ({ organizationId }: GetOrganizationStatisticsParams) =>
    `/organizations/${organizationId}/statistics`,
  call: ({ organizationId }: GetOrganizationStatisticsParams) =>
    axiosInstance
      .get<GetOrganizationStatisticsResponse>(
        getOrganizationStatisticsEndpoint.path({ organizationId }),
      )
      .then((response) => response.data),
};

export function useGetOrganizationStatistics(
  params: GetOrganizationStatisticsParams,
) {
  return useQuery({
    queryKey: getAPIQueryKey(getOrganizationStatisticsEndpoint.path(params)),
    queryFn: () => getOrganizationStatisticsEndpoint.call(params),
  });
}
