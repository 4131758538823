import React from "react";

import { Navigate } from "react-router-dom";

import LoginPage from "@pages/login";
import useAuthentication from "@services/authentication/useAuthentication";

export default function IndexPage() {
  const { currentUser } = useAuthentication();
  if (!currentUser) {
    return <LoginPage />;
  }

  if (currentUser.role === "FREELANCE") {
    return <Navigate to="/my-profile" replace />;
  }

  if (currentUser.role === "CONTACT_BUYER") {
    return <Navigate to="/cruising" replace />;
  }

  return <Navigate to="/dashboard" replace />;
}
