import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  addHours,
  addMinutes,
  compareAsc,
  getYear,
  isAfter,
  isBefore,
} from "date-fns";
import { useForm } from "react-hook-form";
import { clone } from "remeda";
import { z } from "zod";

import { removeValueWithId, toggleValue } from "@helpers/Array";
import { combineDateAndTime, generateTime, toLocalTime } from "@helpers/Date";
import {
  Representative,
  organizationRepresentativeSchema,
} from "@models/OrganizationRepresentative";
import { openingDayHydratingSchema } from "@models/Showroom";
import { Address, addressSchema } from "@models/types/Address";
import {
  AppointmentFormat,
  AppointmentTypeEnum,
  CollectionAgnosticAppointmentTypeList,
  CollectionRelatedAppointmentTypeList,
  ShowroomSeason,
  ShowroomSeasonList,
  VirtualMeetingApps,
  appointmentFormatSchema,
  appointmentTypeSchema,
} from "@models/types/enums";
import { fullName } from "@shared/helpers/formatters";
import { useEnhancedForm } from "@shared/hooks/useEnhancedForm";
import {
  getCustomHoursConfig,
  getShowroomHoursOnDate,
} from "@shared/showroom/helpers";

export const validShowroomFormDataSchema = z.object({
  // Global informations
  name: z.string().min(1),
  address: addressSchema, // remove nullable
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
  timezone: z.string().min(1),
  directions: z.string(),
  openingDays: z
    .array(
      z.object({
        day: z.date(),
        keyAccountsOpeningHour: z.date().nullable(),
        keyAccountsClosingHour: z.date().nullable(),
        customOpeningHour: z.date().nullable().default(null),
        customClosingHour: z.date().nullable().default(null),
      }),
    )
    .nonempty(),
  openingHour: z.date(),
  closingHour: z.date(),
  lunchStartingHour: z.date().nullable(),
  lunchEndingHour: z.date().nullable(),

  // Structure
  collections: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        brand: z.object({
          id: z.string(),
          name: z.string(),
        }),
      }),
    )
    .nonempty(),
  appointmentTypes: z.array(appointmentTypeSchema).nonempty(),
  sellers: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        virtualMeetingApps:
          organizationRepresentativeSchema.shape.virtualMeetingApps,
        appointmentTypes: z.array(appointmentTypeSchema).nonempty(),
      }),
    )
    .nonempty(),

  // Appointments
  appointmentFormats: z.array(appointmentFormatSchema).nonempty(),
  appointmentDurations: z
    .array(
      z.union([
        z.object({
          type: z.enum(CollectionRelatedAppointmentTypeList),
          collectionId: z.string(),
          duration: z.number(),
        }),
        z.object({
          type: z.enum(CollectionAgnosticAppointmentTypeList),
          duration: z.number(),
        }),
      ]),
    )
    .nonempty(),
  specialRequestsAllowed: z.boolean(),
  numberOfSeats: z.number().nullable(),
  increment: z.number().nullable(),

  // Restrictions
  appointmentDeadline: z.date().nullable(),
  keyAccountDatetime: z.date().nullable(),

  // Emails part
  existingAttachments: z.record(z.string(), z.string()), // already uploaded attachments
  newAttachments: z.array(z.instanceof(File)), // newly uploaded attachments
}); // validate that openingHour is before closingHour

// validate that openingHour is before closingHour
export const validateOpeningHours = validShowroomFormDataSchema.refine(
  (data) => data.openingHour < data.closingHour,
  {
    message: "Opening hour must be before closing hour",
    path: ["openingHour, closingHour"],
  },
);

// validate that customOpeningHour is before customClosingHour
export const validateCustomOpeningHours = validShowroomFormDataSchema.refine(
  (data) =>
    data.openingDays.every(
      (openingDay) =>
        openingDay.customOpeningHour &&
        openingDay.customClosingHour &&
        openingDay.customOpeningHour < openingDay.customClosingHour,
    ),
  {
    message: "Custom opening hour must be before custom closing hour",
    path: ["customOpeningHour, customClosingHour"],
  },
);

export type ValidShowroomFormData = z.infer<typeof validShowroomFormDataSchema>;

export const showroomFormSchema = z.object({
  // Global informations
  name: z.string(),
  season: z.enum(ShowroomSeasonList).nullable(),
  year: z.number().nullable(),
  address: addressSchema.nullable(),
  timezone: z.string().nullable(),
  directions: z.string(),
  openingDays: z.array(openingDayHydratingSchema),
  openingHour: z.date(),
  closingHour: z.date(),
  lunchStartingHour: z.date().nullable(),
  lunchEndingHour: z.date().nullable(),

  // Structure
  collections: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      brand: z.object({
        id: z.string(),
        name: z.string(),
      }),
    }),
  ),
  appointmentTypes: z.array(appointmentTypeSchema),
  sellers: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      virtualMeetingApps:
        organizationRepresentativeSchema.shape.virtualMeetingApps,
      appointmentTypes: z.array(appointmentTypeSchema),
    }),
  ),

  // Appointments
  appointmentFormats: z.array(appointmentFormatSchema),
  appointmentDurations: z.array(
    z.union([
      z.object({
        type: z.enum(CollectionRelatedAppointmentTypeList),
        collectionId: z.string(),
        duration: z.number(),
      }),
      z.object({
        type: z.enum(CollectionAgnosticAppointmentTypeList),
        duration: z.number(),
      }),
    ]),
  ),
  specialRequestsAllowed: z.boolean(),
  numberOfSeats: z.number().nullable(),
  increment: z.number().nullable(),

  // Restrictions
  appointmentDeadline: z.date().nullable(),
  keyAccountDatetime: z.date().nullable(),

  // Emails part
  existingAttachments: z.record(z.string(), z.string()), // already uploaded attachments
  newAttachments: z.array(z.instanceof(File)), // newly uploaded attachments
});
export type ShowroomFormData = z.infer<typeof showroomFormSchema>;

function getCurrentSeason(): ShowroomSeason {
  const currentMonth = new Date().getMonth();
  if (currentMonth < 6) {
    return "SS";
  }
  return "AW";
}

export const defaultFormValues: ShowroomFormData = {
  name: "",
  season: getCurrentSeason(),
  year: getYear(new Date()),
  address: null,
  timezone: "",
  directions: "",
  openingDays: [],
  openingHour: generateTime(9, 0),
  closingHour: generateTime(19, 0),
  lunchStartingHour: null,
  lunchEndingHour: null,
  collections: [],
  numberOfSeats: null,
  specialRequestsAllowed: false,
  appointmentTypes: [AppointmentTypeEnum.BUYING_APPOINTMENT],
  sellers: [],
  appointmentFormats: ["IN_PERSON"],
  appointmentDurations: [],
  increment: null,
  appointmentDeadline: null,
  keyAccountDatetime: null,
  existingAttachments: {},
  newAttachments: [],
};

interface FormProps<Data> {
  onSave?: (d: Data) => void;
  onSubmit?: (d: Data) => void;
}

interface UseShowroomFormProps extends FormProps<ShowroomFormData> {
  initialStep?: number;
  defaultValues?: Partial<ShowroomFormData>;
  schema: z.ZodObject<any>;
}

export function useShowroomForm({
  defaultValues,
  initialStep = 0,
  onSave = () => {},
  schema,
}: UseShowroomFormProps) {
  const [formStep, setFormStep] = useState(initialStep);

  // fill form data with showroom
  const form = useForm<ShowroomFormData>({
    defaultValues: {
      ...defaultFormValues,
      ...defaultValues,
    },
    resolver: zodResolver(schema),
  });
  const { validate } = useEnhancedForm(form);

  const saveStep = (d: ShowroomFormData, step: number) => {
    setFormStep(step);
    onSave(d);
  };

  const {
    appointmentTypes,
    sellers,
    openingHour,
    closingHour,
    lunchEndingHour,
    appointmentFormats,
    appointmentDurations,
    newAttachments,
    existingAttachments,
    openingDays,
  } = form.watch();

  const setName = (n: string) => form.setValue("name", n);
  const setSeasonYear = (season: ShowroomSeason, year: number) => {
    form.setValue("season", season);
    form.setValue("year", year);
  };
  const setAddress = (a: Address | null) => form.setValue("address", a);
  const setTimezone = (tz: string | null) => form.setValue("timezone", tz);
  const setDirections = (directions: string) =>
    form.setValue("directions", directions);

  const setOpeningDays = (date: Date | Date[] | null) => {
    let newOpeningDays: ShowroomFormData["openingDays"] = [];
    if (Array.isArray(date)) {
      date.sort(compareAsc);
      newOpeningDays = date.map((d) => ({
        day: d,
        customClosingHour: null,
        customOpeningHour: null,
        keyAccountsClosingHour: null,
        keyAccountsOpeningHour: null,
      }));
    } else if (date !== null) {
      newOpeningDays = [
        {
          day: date,
          customClosingHour: null,
          customOpeningHour: null,
          keyAccountsClosingHour: null,
          keyAccountsOpeningHour: null,
        },
      ];
    }
    validate.array.nonEmpty("openingDays", newOpeningDays);
    form.setValue("openingDays", newOpeningDays);
  };

  const toggleAppointmentType = (
    checked: boolean,
    type: AppointmentTypeEnum,
  ) => {
    const value = toggleValue(checked, type, appointmentTypes);
    validate.array.nonEmpty("appointmentTypes", value);
    form.setValue("appointmentTypes", value);
    // toggle value for each added seller
    form.setValue(
      "sellers",
      sellers.map((s) => ({
        ...s,
        appointmentTypes: toggleValue(checked, type, s.appointmentTypes),
      })),
    );
  };

  const addSeller = (representative: Representative) => {
    form.clearErrors("sellers");
    form.setValue(
      "sellers",
      sellers.concat({
        id: representative.id,
        name: fullName(representative),
        virtualMeetingApps: Object.keys(
          representative.virtualMeetingAppLinks || {},
        ) as VirtualMeetingApps[],
        appointmentTypes,
      }),
    );
  };

  const removeSeller = (representativeId: string) => {
    const value = removeValueWithId(sellers, representativeId);
    validate.array.nonEmpty("sellers", value);
    form.setValue("sellers", value);
  };

  const setSellerAppointmentTypes = (
    index: number,
    types: AppointmentTypeEnum[],
  ) => {
    form.setValue(`sellers.${index}.appointmentTypes`, types);
  };

  const setOpeningHour = (newOpeningHour: Date) => {
    if (newOpeningHour >= closingHour) {
      form.setValue("closingHour", addHours(newOpeningHour, 1));
    } else {
      form.setValue("closingHour", closingHour); // refreshes the input
    }
    form.setValue("openingHour", newOpeningHour);
  };

  const setClosingHour = (newClosingHour: Date) =>
    form.setValue("closingHour", newClosingHour);

  const setLunchStartingHour = (newLunchStartingHour: Date) => {
    if (!newLunchStartingHour) {
      form.setValue("lunchEndingHour", null);
    } else if (!lunchEndingHour || newLunchStartingHour >= lunchEndingHour) {
      form.setValue("lunchEndingHour", addMinutes(newLunchStartingHour, 15));
    } else {
      // set value to refresh the input
      form.setValue("lunchEndingHour", lunchEndingHour);
    }
    form.setValue("lunchStartingHour", newLunchStartingHour);
  };
  const setLunchEndingHour = (d: Date) => form.setValue("lunchEndingHour", d);

  const toggleAppointmentFormat = (
    checked: boolean,
    format: AppointmentFormat,
  ) => {
    const value = toggleValue(checked, format, appointmentFormats);
    validate.array.nonEmpty("appointmentFormats", value);
    form.setValue("appointmentFormats", value);
  };

  const removeDeadline = () => {
    form.setValue("appointmentDeadline", null);
  };

  const setDeadline = (date: Date) => {
    form.setValue("appointmentDeadline", date);
  };

  const setKeyAccountDatetime = (newValue: Date) => {
    form.setValue("keyAccountDatetime", newValue);
    // propagate the change to the "openingDays"
    const newOpeningDays = openingDays.map((od) => {
      const hoursThatDay = getShowroomHoursOnDate(od.day, {
        openingHour,
        closingHour,
        customOpeningHoursByDay: getCustomHoursConfig(openingDays),
        openingDays,
      });

      let keyAccountsOpeningHour = null;
      let keyAccountsClosingHour = null;
      // we set the keyAccountOpeningHour if that openingDay is before the keyAccountClosingDatetime
      if (
        isBefore(combineDateAndTime(od.day, hoursThatDay.opening), newValue)
      ) {
        keyAccountsOpeningHour = toLocalTime(hoursThatDay.opening);

        if (
          isAfter(combineDateAndTime(od.day, hoursThatDay.closing), newValue)
        ) {
          keyAccountsClosingHour = toLocalTime(new Date(newValue));
        } else {
          keyAccountsClosingHour = toLocalTime(hoursThatDay.closing);
        }
      }

      return {
        ...od,
        keyAccountsOpeningHour,
        keyAccountsClosingHour,
      };
    });
    form.setValue("openingDays", newOpeningDays);
  };

  const removeKeyAccountDatetime = () => {
    form.setValue("keyAccountDatetime", null);
    const newOpeningDays = openingDays.map((od) => ({
      ...od,
      keyAccountsClosingHour: null,
      keyAccountsOpeningHour: null,
    }));
    form.setValue("openingDays", newOpeningDays);
  };

  const setIncrement = (value: string | undefined) => {
    form.setValue("increment", value ? parseInt(value, 10) : null);
  };

  const setAppointmentDuration = (
    type: AppointmentTypeEnum,
    collectionId: string | null,
    duration: number,
  ) => {
    const newDuration = collectionId
      ? {
          type,
          collectionId,
          duration,
        }
      : {
          type,
          duration,
        };
    const existingDurationIdx = appointmentDurations.findIndex(
      (atd) =>
        // find the item with the same type
        atd.type === newDuration.type &&
        // and if specified, the same collection
        (collectionId && "collectionId" in atd
          ? atd.collectionId === newDuration.collectionId
          : true),
    );
    if (existingDurationIdx !== -1) {
      form.setValue(`appointmentDurations.${existingDurationIdx}`, newDuration);
    } else {
      form.setValue("appointmentDurations", [
        ...appointmentDurations,
        newDuration,
      ]);
    }
  };

  const addAttachments = (f: File[]) => {
    form.setValue("newAttachments", newAttachments.concat(...f));
  };
  const removeNewAttachment = (index: number) => {
    form.setValue(
      "newAttachments",
      newAttachments.filter((_i, idx) => idx !== index),
    );
  };
  const removeExistingAttachment = (name: string) => {
    // @todo call endpoint to remove file
    const tmp = clone(existingAttachments);
    delete tmp[name];
    form.setValue("existingAttachments", tmp);
  };

  const setNumberOfSeats = (v: string) => {
    const onlyNumber = v.replace(/\D/g, "");
    form.setValue("numberOfSeats", onlyNumber ? parseInt(v, 10) : null);
  };

  const toggleSpecialRequest = (v: boolean) => {
    form.setValue("specialRequestsAllowed", v);
  };

  return {
    form,
    formStep,
    saveStep,
    setFormStep,

    setName,
    setSeasonYear,
    setAddress,
    setTimezone,
    setDirections,
    setOpeningDays,

    setOpeningHour,
    setClosingHour,
    setLunchStartingHour,
    setLunchEndingHour,

    toggleAppointmentType,
    toggleAppointmentFormat,

    addSeller,
    removeSeller,
    setSellerAppointmentTypes,

    setDeadline,
    removeDeadline,

    setKeyAccountDatetime,
    removeKeyAccountDatetime,

    setIncrement,

    setAppointmentDuration,

    addAttachments,
    removeExistingAttachment,
    removeNewAttachment,

    setNumberOfSeats,
    toggleSpecialRequest,
  };
}

export default {
  useShowroomForm,
};
