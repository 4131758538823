import React, { PropsWithChildren, forwardRef } from "react";

/**
 * CONDITIONAL RENDERING WORKFLOW
 * @see https://excalidraw.com/#json=xeuSyZizkL06KNc52lgpI,YJYKdGlAyeU4lz0iv5MGOQ
 *
 * Usage covers those needs :
 * - I want to display a button if it is not disabled
 * - I want to display a tooltip if there are anything to show
 */
export interface ConditionalRenderingProps<
  ComponentProps extends PropsWithChildren<{}>,
> {
  /**
   * default: true
   *
   * If this prop is true, the component will render normally
   */
  renderIf?: boolean;
  /**
   * default: null
   *
   * If this prop is not falsy, it will render if "renderIf" is false
   * If set, "fallbackProp" will be ignored
   */
  fallback?: React.ReactNode;
  /**
   * default: undefined
   *
   * Use this prop to render a component's prop as a fallback
   * Examples :
   * - fallbackProp="children" will render the children if "renderIf" is false
   * - fallbackProp="content" will render the prop "content" if "renderIf" is false
   */
  fallbackProp?: keyof ComponentProps;
}

/**
 * This HOC helps rendering or wrapping components conditionnally
 */
export default function WithConditionalRendering<ComponentProps extends {}>(
  Component: React.ComponentType<ComponentProps>,
) {
  return forwardRef(
    (
      props: ComponentProps & ConditionalRenderingProps<ComponentProps>,
      ref,
    ) => {
      const {
        renderIf = true,
        fallback = null,
        fallbackProp = undefined,
        ...rest
      } = props;

      if (renderIf) {
        return <Component ref={ref} {...(rest as ComponentProps)} />;
      }

      if (fallback) {
        return fallback;
      }

      if (fallbackProp) {
        return props[fallbackProp] as React.ReactNode;
      }

      return null;
    },
  );
}
