import React, { PropsWithChildren } from "react";

interface DashboardPanelProps extends PropsWithChildren<{}> {
  className: string;
  title?: React.ReactNode;
  textCenter?: boolean;
  adjustHeight?: boolean;
  filterContent?: React.ReactNode;
}

export function DashboardPanel({
  children,
  className,
  title,
  textCenter,
  adjustHeight,
  filterContent,
}: DashboardPanelProps) {
  if (!filterContent) {
    return (
      <div
        style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
        className={`bg-white p-6 mb-4 rounded-lg ${className}`}
      >
        <div className={`${adjustHeight ? "h-12 xl:h-auto" : ""}`}>
          {title && (
            <h3 className={`font-bold ${textCenter ? "text-center" : ""}`}>
              {title}
            </h3>
          )}
        </div>
        {children}
      </div>
    );
  }

  return (
    <div
      style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
      className={`bg-white p-6 mb-4 rounded-lg ${className}`}
    >
      <div
        className={`flex flex-row justify-between ${adjustHeight ? "h-12 xl:h-auto" : ""}`}
      >
        {title && (
          <h3 className={`font-bold ${textCenter ? "text-center" : ""}`}>
            {title}
          </h3>
        )}
        {filterContent}
      </div>
      {children}
    </div>
  );
}
