import React from "react";

import { t } from "i18next";

import Tabs, { TabsProps, useTabs } from "@components/data-display/Tabs";

interface MyProfileHeaderProps {
  name: string;
  onChangeTab: (tab: MyProfileTab) => void;
}

export type MyProfileTab = "personal-info" | "virtual-tools" | "login";

function MyProfileHeader({ name, onChangeTab }: MyProfileHeaderProps) {
  const tabs: TabsProps["items"] = [
    {
      label: t("MyProfile.header.tabs.personal-info"),
      tab: "personal-info" as MyProfileTab,
    },
    {
      label: t("MyProfile.header.tabs.virtual-tool"),
      tab: "virtual-tools" as MyProfileTab,
    },
    {
      label: t("MyProfile.header.tabs.login"),
      tab: "login" as MyProfileTab,
    },
  ];

  const { tab: currentTab, setTab } = useTabs({
    initial: tabs[0].tab,
  });

  return (
    <>
      <div className="pt-12 px-10 flex justify-between">
        <h1 className="heading-1">{name}</h1>
      </div>
      <Tabs
        aria-label="My-Profile-tabs"
        className="mt-10 mb-4 border-b"
        items={tabs}
        tab={currentTab}
        handleClick={(item) => {
          setTab(item.tab);
          onChangeTab(item.tab as MyProfileTab);
        }}
      />
    </>
  );
}

export default MyProfileHeader;
