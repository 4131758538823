import * as Sentry from "@sentry/react";

export default class LogService {
  static error(error: any, boundary: boolean = false) {
    // eslint-disable-next-line no-console
    if (error.message) {
      console.error(error.message);
    } else {
      console.error(error);
    }
    if (typeof error === "string" || error instanceof String) {
      Sentry.captureMessage(error as string);
    } else if (error.target) {
      Sentry.captureEvent(error);
    } else {
      Sentry.captureException(error, (scope) => {
        scope.setExtra("error", error);
        scope.setExtra("boundary", boundary);
        return scope;
      });
    }
  }

  static apiRequestError(error: any) {
    console.log(error);
  }
}
