import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Statistic from "@components/data-display/Statistic";
import Loading from "@components/feedback/Loading";
import { useGetOrganizationStatistics } from "@services/api/organization/get-statistics";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { AccessControl } from "@shared/components/access-control";
import { useUserRole } from "@shared/components/access-control/helpers";

import SalesCampaignsStatistics from "./sales-campaigns-statistics";

function OrganizationStatistics() {
  const { t } = useTranslation();
  const { organization } = useOrganizationAppContext();
  const navigate = useNavigate();
  const { id: organizationId } = organization;

  const { data: organizationData, isLoading } = useGetOrganizationStatistics({
    organizationId,
  });
  const { isAgent } = useUserRole();

  if (isLoading) {
    return <Loading type="screen" />;
  }

  if (!organizationData) {
    throw new Error("Organization statistics missing");
  }

  const { activeAccountsCount, prospectAccountsCount, contactsCount } =
    organizationData;

  return (
    <div className="flex flex-col gap-6 px-6 grow">
      <AccessControl
        roles={[
          "ORGANIZATION_ADMIN",
          "ORGANIZATION_MAIN_ADMIN",
          "ORGANIZATION_MANAGER",
          "AGENT",
        ]}
      >
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
          <Statistic
            title={t(
              isAgent
                ? "Dashboard.agent-organization-statistics.active-account-count"
                : "Dashboard.organization-statistics.active-account-count",
            )}
            value={activeAccountsCount}
            onClick={() => navigate("/crm/accounts?status=ACTIVE")}
            className="w-full sm:w-1/4"
          />
          <Statistic
            title={t(
              isAgent
                ? "Dashboard.agent-organization-statistics.prospect-accounts-count"
                : "Dashboard.organization-statistics.prospect-accounts-count",
            )}
            value={prospectAccountsCount}
            onClick={() => navigate("/crm/accounts?status=PROSPECT")}
            className="w-full sm:w-1/4"
          />
          <Statistic
            title={t(
              isAgent
                ? "Dashboard.agent-organization-statistics.contacts-count"
                : "Dashboard.organization-statistics.contacts-count",
            )}
            value={contactsCount}
            onClick={() => navigate("/crm/contacts")}
            className="w-full sm:w-1/4"
          />
        </div>
      </AccessControl>
      <div className="flex grow">
        <SalesCampaignsStatistics />
      </div>
    </div>
  );
}

export default OrganizationStatistics;
