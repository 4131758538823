import React from "react";

import { useTranslation } from "react-i18next";

import { DashboardPanel } from "@components/data-display/DashboardPanel";

interface AppointmentStatusStatisticsProps {
  totalAppointmentsToday: number;
  completedAppointmentsToday: number;
  canceledAppointmentsToday: number;
  ongoingAppointmentsNow: number;
  buyersWaitingNow: number;
}

export function AppointmentStatusStatistics({
  totalAppointmentsToday,
  completedAppointmentsToday,
  canceledAppointmentsToday,
  ongoingAppointmentsNow,
  buyersWaitingNow,
}: AppointmentStatusStatisticsProps) {
  const { t } = useTranslation();
  return (
    <>
      <DashboardPanel
        className="col-span-2 h-full"
        title={t("Dashboard.appointment-status.total-appointments")}
        textCenter
        adjustHeight
      >
        <div className="pt-5 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {totalAppointmentsToday}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {totalAppointmentsToday === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>
      </DashboardPanel>
      <DashboardPanel
        className="col-span-2 h-full"
        title={t("Dashboard.appointment-status.completed-appointments")}
        textCenter
        adjustHeight
      >
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {completedAppointmentsToday}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {completedAppointmentsToday === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>{" "}
      </DashboardPanel>
      <DashboardPanel
        className="col-span-2 h-full"
        title={t("Dashboard.appointment-status.canceled-appointments")}
        textCenter
        adjustHeight
      >
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {canceledAppointmentsToday}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {canceledAppointmentsToday === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>{" "}
      </DashboardPanel>
      <DashboardPanel
        className="col-span-2 h-full"
        title={t("Dashboard.appointment-status.ongoing-appointments")}
        textCenter
        adjustHeight
      >
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {ongoingAppointmentsNow}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {ongoingAppointmentsNow === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>
      </DashboardPanel>
      <DashboardPanel
        className="col-span-2 h-full"
        title={t("Dashboard.appointment-status.buyers-arrived")}
        textCenter
        adjustHeight
      >
        <div className="pt-4 text-center">
          <p className="text-4xl font-bold text-primaryElectricBlue">
            {buyersWaitingNow}
          </p>
          <p className="font-light text-sm text-center pt-4">
            {buyersWaitingNow === 1
              ? `${t("Dashboard.appointment-status.appointment")}`
              : `${t("Dashboard.appointment-status.appointments")}`}
          </p>
        </div>{" "}
      </DashboardPanel>
    </>
  );
}
