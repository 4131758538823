import React from "react";

import { Pie, PieChart, Sector, Tooltip } from "recharts";

import { placeholderData } from "./chart-data";
import EmptyChartTooltip from "./empty-chart-tooltip";

const renderActiveShape = () =>
  function innerRenderer(props: any) {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          ...
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#F0F0F0"
        >
          ...%
        </text>
      </g>
    );
  };

function PieChartPlaceholder() {
  return (
    <PieChart height={300} width={400}>
      <Pie
        activeIndex={0}
        activeShape={renderActiveShape()}
        data={placeholderData}
        cx="50%"
        cy="50%"
        innerRadius={60}
        outerRadius={80}
        fill="#F0F0F0"
        dataKey="value"
      />
      <Tooltip cursor={{ fill: "#FAFAFA" }} content={<EmptyChartTooltip />} />
    </PieChart>
  );
}

export default PieChartPlaceholder;
