import React from "react";

import { useTranslation } from "react-i18next";

function EmptyChartTooltip() {
  const { t } = useTranslation();
  return (
    <div className="px-6 py-4 bg-white border rounded-lg border-primaryLightElectricBlue">
      <p className="label">{`${t(`Charts.missing-data`)}`}</p>
    </div>
  );
}

export default EmptyChartTooltip;
