import React from "react";

import { UseMutationResult } from "@tanstack/react-query";

import ShowroomAppointmentsForm from "@app/modules/showroom/form/appointments";
import ShowroomAttachmentsForm from "@app/modules/showroom/form/attachments";
import ShowroomGlobalInformationsForm from "@app/modules/showroom/form/global-informations";
import {
  ShowroomFormData,
  ValidShowroomFormData,
  defaultFormValues,
  validShowroomFormDataSchema,
} from "@app/modules/showroom/form/hook";
import ShowroomRestrictionForm from "@app/modules/showroom/form/restrictions";
import ShowroomStructureForm from "@app/modules/showroom/form/structure";
import Tabs from "@components/data-display/Tabs";
import CalloutBox from "@components/feedback/CalloutBox";

type Props = {
  defaultValues: Partial<ShowroomFormData>;
  onSubmit: (data: ValidShowroomFormData) => Promise<void>;
  submitStatus: UseMutationResult["status"];
  onStepChange: (data: ShowroomFormData) => void;
};

export default function ShowroomForm({
  onSubmit,
  defaultValues,
  onStepChange,
  submitStatus,
}: Props) {
  const [formStep, setFormStep] = React.useState(0);
  const [formData, setFormData] = React.useState<ShowroomFormData>({
    ...defaultFormValues,
    ...defaultValues,
  });
  const [errors, setErrors] = React.useState<string[]>([]);

  const saveAndGotoStep =
    (step: number) =>
    (data: ShowroomFormData): void => {
      console.log("step change", step, data);
      setFormData(data);
      setFormStep(step);
      onStepChange(data);
    };

  const gotoStep = (step: number) => (): void => {
    setFormStep(step);
  };

  const handleSubmit = (submittedData: ShowroomFormData) => {
    const validation = validShowroomFormDataSchema.safeParse(submittedData);

    if (!validation.success) {
      setErrors(validation.error.errors.map((e) => e.message));
      return Promise.reject();
    }

    setFormData(validation.data);
    return onSubmit(validation.data).then(() => {
      setFormStep(0);
      setFormData(defaultFormValues);
    });
  };

  return (
    <div className="flex flex-col min-h-full">
      <Tabs
        tabWidth={120}
        items={[
          {
            label: "Global information",
            tab: 0,
          },
          {
            label: "Structure",
            tab: 1,
            disabled: (step) => parseInt(`${step}`, 10) < 1,
          },
          {
            label: "Appointments",
            tab: 2,
            disabled: (step) => parseInt(`${step}`, 10) < 2,
          },
          {
            label: "Restrictions",
            tab: 3,
            disabled: (step) => parseInt(`${step}`, 10) < 3,
          },
          {
            label: "Email",
            tab: 4,
            disabled: (step) => parseInt(`${step}`, 10) < 4,
          },
        ]}
        handleClick={(item): void => {
          gotoStep(parseInt(`${item.tab}`, 10))();
        }}
        tab={formStep}
      />

      {errors && errors.length > 0 && (
        <CalloutBox type="ERROR">
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </CalloutBox>
      )}

      {formStep === 0 && (
        <ShowroomGlobalInformationsForm
          defaultValues={formData}
          onSubmit={saveAndGotoStep(1)}
        />
      )}
      {formStep === 1 && (
        <ShowroomStructureForm
          defaultValues={formData}
          onSubmit={saveAndGotoStep(2)}
          onBack={gotoStep(0)}
        />
      )}
      {formStep === 2 && (
        <ShowroomAppointmentsForm
          defaultValues={formData}
          onSubmit={saveAndGotoStep(3)}
          onBack={gotoStep(1)}
        />
      )}
      {formStep === 3 && (
        <ShowroomRestrictionForm
          defaultValues={formData}
          onSubmit={saveAndGotoStep(4)}
          onBack={gotoStep(2)}
        />
      )}
      {formStep === 4 && (
        <ShowroomAttachmentsForm
          defaultValues={formData}
          onSubmit={handleSubmit}
          onBack={gotoStep(3)}
          disableSubmit={submitStatus === "pending"}
        />
      )}
    </div>
  );
}
