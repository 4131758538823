import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { sumBy } from "remeda";

import SingleSelect from "@components/data-entry/SingleSelect";
import Loading from "@components/feedback/Loading";
import { isActiveSalesCampaign } from "@sales-campaign/helpers/sales-campaign";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useGetShowroomStatistics } from "@services/api/showroom/get-statistics";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

import CustomBarChart from "../charts/bar-chart";
import BarChartPlaceholder from "../charts/empty-bar-chart";
import PieChartPlaceholder from "../charts/empty-pie-chart";
import CustomDoughnutChart from "../charts/pie-chart";
import { isActiveShowroom } from "../showroom/helpers";

function SalesCampaignsStatistics() {
  const { t } = useTranslation();

  const [currentShowroomId, setCurrentShowroomId] = useState<
    string | undefined
  >();
  const { organization } = useOrganizationAppContext();
  const { id: organizationId } = organization;

  const { data, isPending } = useGetShowroomStatistics({
    organizationId,
    showroomId: currentShowroomId || null,
  });

  const { data: showrooms = [] } = GetOngoingShowroomsEndpoint.useHook({
    organizationId,
  });

  if (!currentShowroomId || (!data && !isPending)) {
    return (
      <div
        style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
        className="flex flex-col items-center justify-center gap-4 p-10 rounded-lg grow"
      >
        <p> {t("Dashboard.sales-campaigns-statistics.select-campaign")}</p>
        <SingleSelect
          options={showrooms.filter(isActiveShowroom).map((sc) => ({
            label: sc.name,
            value: sc.id,
          }))}
          onChange={(option) => setCurrentShowroomId(option?.value)}
        />
      </div>
    );
  }

  if (isPending) {
    return (
      <div
        style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
        className="flex flex-col items-center justify-center gap-4 p-10 rounded-lg grow"
      >
        <SingleSelect
          options={showrooms?.filter(isActiveSalesCampaign).map((sc) => ({
            label: sc.name,
            value: sc.id,
          }))}
          onChange={(option) => setCurrentShowroomId(option?.value)}
        />
        <Loading type="screen" />
      </div>
    );
  }

  const noInvitationEmails = Object.entries(data.invitationEmails).length === 0;
  const noFollowUpEmails = Object.entries(data.followUpEmails).length === 0;

  const noInvitationData = Object.entries(data.invitations).length === 0;
  const noAppointmentTypeData =
    Object.entries(data.appointments.type).length === 0;
  const noAppointmentFormatData =
    Object.entries(data.appointments.format).length === 0;

  return (
    <div
      style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
      className="flex flex-col gap-4 p-10 rounded-lg grow"
    >
      <SingleSelect
        options={showrooms?.filter(isActiveSalesCampaign).map((sc) => ({
          label: sc.name,
          value: sc.id,
        }))}
        onChange={(option) => setCurrentShowroomId(option?.value)}
      />
      <h2 className="heading-2 ">
        {t("Dashboard.sales-campaigns-statistics.title")}
      </h2>
      <div className="flex flex-row justify-between gap-4">
        {/* INVITATIONS STATS */}
        <div className="flex flex-col w-full gap-2">
          <h3 className="heading-3">
            {t(
              "Dashboard.sales-campaigns-statistics.invitation-email-tracking.title",
            )}
          </h3>
          <p className="text-primaryGrey text-md">
            {t(
              "Dashboard.sales-campaigns-statistics.invitation-email-tracking.description",
            )}
          </p>
          {noInvitationEmails && <BarChartPlaceholder />}
          {!noInvitationEmails && (
            <CustomBarChart
              data={[
                {
                  name: "TOTAL",
                  value: sumBy(Object.values(data.invitationEmails), (v) => v),
                },
                ...Object.entries(data.invitationEmails).map((status) => ({
                  name: status[0],
                  value: status[1],
                })),
              ]}
              max="auto"
            />
          )}
        </div>
        <div className="flex flex-col w-full gap-2">
          <h3 className="heading-3">
            {t(
              "Dashboard.sales-campaigns-statistics.follow-up-email-tracking.title",
            )}
          </h3>
          <p className="text-primaryGrey text-md">
            {t(
              "Dashboard.sales-campaigns-statistics.follow-up-email-tracking.description",
            )}
          </p>
          {noFollowUpEmails && <BarChartPlaceholder />}
          {!noFollowUpEmails && (
            <CustomBarChart
              data={[
                {
                  name: "TOTAL",
                  value: sumBy(Object.values(data.followUpEmails), (v) => v),
                },
                ...Object.entries(data.followUpEmails).map((status) => ({
                  name: status[0],
                  value: status[1],
                })),
              ]}
              max="auto"
            />
          )}
        </div>
      </div>
      <div className="flex flex-col flex-wrap gap-6 grow lg:flex-row">
        {/* INVITATION STATS */}
        <div className="flex flex-col w-full gap-4 2xl:w-1/2">
          <h3 className=" heading-3">
            {t("Dashboard.sales-campaigns-statistics.invitations.title")}
          </h3>
          <p className="text-primaryGrey text-md ">
            {t("Dashboard.sales-campaigns-statistics.invitations.description")}
          </p>
          {noInvitationData && <BarChartPlaceholder />}
          {!noInvitationData && (
            <CustomBarChart
              data={[
                {
                  name: "TOTAL",
                  value: sumBy(Object.values(data.invitations), (v) => v),
                },
                ...Object.entries(data.invitations).map((invitation) => ({
                  name: invitation[0],
                  value: invitation[1],
                })),
              ]}
              max="auto"
            />
          )}
        </div>
        <div className="flex flex-row justify-between gap-4 grow 2xl:w-9/20">
          {/* TYPE STATS */}
          <div className="flex flex-col w-full gap-2">
            <h3 className="heading-3">
              {t("Dashboard.sales-campaigns-statistics.type.title")}
            </h3>
            <p className="text-primaryGrey text-md">
              {t("Dashboard.sales-campaigns-statistics.type.description")}
            </p>
            {noAppointmentTypeData && <PieChartPlaceholder />}
            {!noAppointmentTypeData && (
              <CustomDoughnutChart
                data={Object.entries(data.appointments.type)
                  .map((b) => ({
                    name: b[0],
                    value: b[1],
                  }))
                  .sort((a, b) => b.value - a.value)}
              />
            )}
          </div>
          {/* FORMAT STATS */}
          <div className="flex flex-col w-full gap-2">
            <h3 className="heading-3">
              {t("Dashboard.sales-campaigns-statistics.format.title")}
            </h3>
            <p className="text-primaryGrey text-md">
              {t("Dashboard.sales-campaigns-statistics.format.description")}
            </p>
            {noAppointmentFormatData && <PieChartPlaceholder />}
            {!noAppointmentFormatData && (
              <CustomDoughnutChart
                data={Object.entries(data.appointments.format)
                  .map((b) => ({
                    name: b[0],
                    value: b[1],
                  }))
                  .sort((a, b) => b.value - a.value)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesCampaignsStatistics;
