import React, { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import {
  addMinutes,
  areIntervalsOverlapping,
  isAfter,
  isEqual,
} from "date-fns";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { FiMapPin } from "react-icons/fi";
import { z } from "zod";

import {
  getAccountStatusIcon,
  getAppointmentFormatIcon,
  getAppointmentFormatLabel,
  getAppointmentTypeLabel,
} from "@calendar/helpers/formatters";
import { Calendar } from "@calendar/types";
import Button from "@components/data-entry/Button";
import DatePicker from "@components/data-entry/DatePicker";
import MultiSelect from "@components/data-entry/MultiSelect";
import Select from "@components/data-entry/Select";
import SingleSelect from "@components/data-entry/SingleSelect";
import TimePicker from "@components/data-entry/TimePicker";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Loading from "@components/feedback/Loading";
import { arrayFilterUnique } from "@helpers/Array";
import { LocalDate, ZonedDate, combineDateAndTime } from "@helpers/Date";
import { Collection } from "@models/Collection";
import { Organization } from "@models/Organization";
import { isAccountDetails } from "@models/old/Account";
import { SellerWithAvailabilityStatus } from "@models/old/Seller";
import { SLOT_DURATION } from "@models/types/Constant";
import {
  AccountAppointmentTypeList,
  AppointmentFormat,
  AppointmentFormatList,
  AppointmentTypeEnum,
  VirtualMeetingAppChoice,
  VirtualMeetingApps,
  virtualMeetingAppWithANYSchema,
} from "@models/types/enums";
import { OrganizationQueries } from "@services/api/old/organization/OrganizationAPI";
import { SalesCampaignQueries } from "@services/api/old/sales-campaign/SalesCampaignAPI";
import { useGetCompetitorsGroups } from "@services/api/organization/get-competitors-groups";
import { GetShowroomEndpoint } from "@services/api/showroom/get-showroom";
import AccountSelect from "@shared/accounts/account-select";
import { fullName } from "@shared/helpers/formatters";
import { useOrganizationRepresentativeOptionsByAvailability } from "@shared/helpers/sellers";
import { translateVirtualApp } from "@shared/helpers/translater";

function sellerSupportsMeetingApps(
  sellerTools: VirtualMeetingApps[],
  appointmentTool: VirtualMeetingApps | "ANY",
) {
  return (
    sellerTools.length > 0 &&
    (appointmentTool === "ANY" || sellerTools.includes(appointmentTool))
  );
}

interface AppointmentDialogEditProps {
  availableCollections: Collection[];
  defaultValues: AccountAppointmentFormData;
  appointment?: Calendar.Appointment;
  otherAppointments: {
    id: string;
    startTime: ZonedDate;
    endTime: ZonedDate;
    account: {
      id: string;
      name: string;
    } | null;
  }[];
  showrooms: {
    id: string;
    name: string;
  }[];
  organizationId: Organization["id"];
  onCancel: () => void;
  onSubmit: (appointment: AccountAppointmentFormDataSubmitted) => Promise<void>;
}

export type AccountAppointmentFormData = {
  id: string | null;
  type: AppointmentTypeEnum | null;
  showroomId: string;
  format: AppointmentFormat | null;
  collectionId: string | null;
  accountId: string | null;
  virtualMeetingApp: VirtualMeetingAppChoice | null;
  date: Date;
  sellerId: string | null;
  contacts: SelectOptionBase[];
  startTime: Date | null;
  endTime: Date | null;
  accountOtb: number | null;
};

const appointmentValidFormData = z.object({
  id: z.string().nullable(),
  showroomId: z.string().uuid(),
  type: z.enum(AccountAppointmentTypeList),
  format: z.enum(AppointmentFormatList),
  collectionId: z.string().nullable(),
  accountId: z.string(),
  virtualMeetingApp: virtualMeetingAppWithANYSchema.nullable(),
  date: z.date(),
  sellerId: z.string(),
  contacts: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ),
  startTime: z.date(),
  endTime: z.date(),
  accountOtb: z.number().nullable().default(null),
});

export type AccountAppointmentFormDataSubmitted = z.infer<
  typeof appointmentValidFormData
>;

export function appointmentToFormData(
  appointment: Calendar.AccountAppointment,
): AccountAppointmentFormDataSubmitted {
  return {
    accountId: appointment.account.id,
    showroomId: appointment.showroom.id,
    collectionId: appointment.collection?.id || null,
    contacts: appointment.attendees.map((att) => ({
      label: fullName(att),
      value: att.id,
    })),
    date: appointment.startTime.toLocalDate(appointment.showroom.timezone),
    startTime: appointment.startTime.toLocalDate(appointment.showroom.timezone),
    endTime: appointment.endTime.toLocalDate(appointment.showroom.timezone),
    format: appointment.format,
    id: appointment.id,
    sellerId: appointment.seller.id,
    type: appointment.type,
    //* @todo default to seller's first app instead, ZOOM is used most generally, so we put this by default
    virtualMeetingApp: appointment.virtualMeetingApp,
    accountOtb: appointment.accountOtb,
  };
}

function AppointmentDialogEdit(props: AppointmentDialogEditProps) {
  const {
    availableCollections,
    defaultValues,
    otherAppointments,
    organizationId,
    onSubmit,
    onCancel,
    appointment,
    showrooms,
  } = props;

  const appointmentId = appointment?.id;

  const {
    setValue,
    getValues,
    resetField,
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AccountAppointmentFormData>({
    defaultValues,
  });

  const accountIdSelected = watch("accountId");
  const collectionIdSelected = watch("collectionId");
  const startTimeSelected = watch("startTime");
  const endTimeSelected = watch("endTime");
  const dateSelected = watch("date");
  const sellerIdSelected = watch("sellerId");
  const formatSelected = watch("format");
  const typeSelected = watch("type");
  const showroomId = watch("showroomId");

  const [sellers, setSellers] = useState<SellerWithAvailabilityStatus[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [onSubmitError, setOnSubmitError] = useState<string | null>(null);
  const [isSellerRemoved, setIsSellerRemoved] = useState<boolean>(false);
  const [needsConfirmation, setNeedsConfirmation] = useState<boolean>(false);

  const sellerOptions = useOrganizationRepresentativeOptionsByAvailability({
    sellers,
  });

  const {
    data: showroom,
    isPending: isLoadingShowroom,
    isError: isErrorShowroom,
    error: showroomError,
  } = GetShowroomEndpoint.useHook({
    organizationId,
    showroomId,
  });

  useEffect(() => {
    if (showroom) {
      // auto fill selects with only one choice
      if (showroom.appointmentTypes.length === 1) {
        setValue("type", showroom.appointmentTypes[0]);
      }
      if (showroom.appointmentFormats.length === 1) {
        setValue("format", showroom.appointmentFormats[0]);
      }
    }
  }, [showroom, setValue]);

  const { data: competitorsGroups } = useGetCompetitorsGroups({
    organizationId,
  });

  const { data: accountList, isFetching: isFetchingAccounts } = useQuery({
    ...OrganizationQueries.getAccounts(organizationId),
  });

  const { data: sellersWithAvailability } = useQuery({
    ...SalesCampaignQueries.getSellersForAppointment(
      organizationId,
      showroomId,
      ZonedDate.toRealDate(
        (startTimeSelected || new Date()) as LocalDate,
        showroom?.timezone || "",
      ),
      ZonedDate.toRealDate(
        (endTimeSelected || new Date()) as LocalDate,
        showroom?.timezone || "",
      ),
      {
        editedAppointmentId: appointmentId,
        // cast because enabled only if set
        accountId: accountIdSelected as string,
        type: typeSelected as AppointmentTypeEnum,
        collectionId: collectionIdSelected as string,
        format: formatSelected as AppointmentFormat,
      },
    ),
    enabled:
      !!showroom &&
      !!startTimeSelected &&
      !!endTimeSelected &&
      !!accountIdSelected &&
      !!typeSelected &&
      !!formatSelected,
    staleTime: 0, // this query has no cache because availabilities change when an appointment is created
  });

  useEffect(() => {
    if (sellersWithAvailability) {
      const seller = sellersWithAvailability.find(
        (m) => m.id === getValues("sellerId"),
      );
      if (seller && seller.availabilityStatus === "BOOKED") {
        setIsSellerRemoved(true);
        resetField("sellerId");
      } else {
        setIsSellerRemoved(false);
      }
      setSellers(sellersWithAvailability);
    }
  }, [getValues, resetField, sellersWithAvailability]);

  const { data: contacts } = useQuery({
    ...OrganizationQueries.getContactsForAccountId(
      organizationId,
      accountIdSelected as string, // cast here because query is disabled if it's not set
    ),
    enabled: !!accountIdSelected,
  });

  const selectedSeller = sellers.find((m) => m.id === sellerIdSelected);
  useEffect(() => {
    if (
      formatSelected === "VIRTUAL" &&
      selectedSeller !== undefined &&
      selectedSeller.virtualMeetingApps.length > 0
    ) {
      setValue("virtualMeetingApp", selectedSeller.virtualMeetingApps[0]);
    }
  }, [formatSelected, selectedSeller, sellerIdSelected, setValue]);

  if (isFetchingAccounts || isLoadingShowroom) {
    return <Loading type="screen" />;
  }

  if (accountList === undefined || accountList.length === 0) {
    return <div>Collections or accounts data missing :</div>;
  }

  if (isErrorShowroom) {
    throw showroomError;
  }

  /**
   * Handler called when either :
   * - submitting the form when there is no competitor scheduled simultaneously
   * - confirming the form submission if there is (using the confirm modal)
   */
  const saveAppointment = (data: AccountAppointmentFormData) => {
    setUpdating(true);
    const validValues = appointmentValidFormData.parse(data);

    onSubmit({
      ...validValues,
      // set startTime & endTime at the selected date
      startTime: combineDateAndTime(validValues.date, validValues.startTime),
      endTime: combineDateAndTime(validValues.date, validValues.endTime),
    })
      .catch((error) => {
        setOnSubmitError(error.response.data.message);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  // Detecting competitors overlapping appointments
  const formAccountId = watch("accountId");
  const formStartTime = watch("startTime");
  const formEndTime = watch("endTime");
  const accountCompetitorsIds = (competitorsGroups || [])
    .filter((cg) => cg.accounts.some((cga) => cga.id === formAccountId))
    .flatMap((cg) => cg.accounts.flatMap((cga) => cga.id))
    .filter(arrayFilterUnique)
    .filter((accId) => accId !== formAccountId);
  const competitorsAppointments =
    formStartTime && formEndTime
      ? otherAppointments.filter(
          (otherAppointment) =>
            otherAppointment.account &&
            accountCompetitorsIds.includes(otherAppointment.account.id) &&
            areIntervalsOverlapping(
              {
                start: otherAppointment.startTime.toLocalDate(
                  showroom.timezone,
                ),
                end: otherAppointment.endTime.toLocalDate(showroom.timezone),
              },
              {
                start: formStartTime,
                end: formEndTime,
              },
            ),
        )
      : [];

  /**
   * Handler called when submitting the form
   * if competitors have a simultaneous appointment, will display a modal, otherwise save the data.
   */
  const submitForm = (formValues: AccountAppointmentFormData) => {
    if (!showroom || !competitorsGroups) {
      return;
    }
    // @todo if the spot is taken by a competitor, display a confirmation modal
    if (competitorsAppointments.length > 0) {
      setNeedsConfirmation(true);
    } else {
      saveAppointment(formValues);
    }
  };

  const sellerHasVirtualMeetingApps =
    selectedSeller !== undefined &&
    selectedSeller.virtualMeetingApps.length > 0;

  const account = appointment?.account;

  return (
    <form
      data-testid="appointment-edit-form"
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="flex flex-col gap-6">
        {account && isAccountDetails(account) && (
          <>
            <div data-testid="appointment-account-name">
              <h2 className="text-3xl font-medium">{account.name}</h2>
            </div>
            <div>
              <div className="flex gap-2">
                <div className="flex items-center gap-1">
                  <FiMapPin />
                  <span data-testid="appointment-account-city">
                    {account.city}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  {getAccountStatusIcon(account.status)}
                  <span className="capitalize">
                    {t(
                      `Calendar.appointment.form.status.${account.status.toString()}`,
                    )}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {(!account || !isAccountDetails(account)) && (
          <>
            <div data-testid="appointment-account-name">
              <h2 className="text-3xl font-medium">
                {t("Calendar.appointment.form.create-new")}
              </h2>
            </div>
            <div className="flex items-center gap-2 item">
              <label className="font-medium" htmlFor="account">
                {t("Calendar.appointment.form.showroom")}:
              </label>
              <span className="w-56">
                <Controller
                  control={control}
                  name="showroomId"
                  render={({ field: { onChange, value } }) => (
                    <SingleSelect
                      onChange={(o) => onChange(o?.value)}
                      value={{
                        label:
                          showrooms.find((s) => s.id === value)?.name || "-",
                        value,
                      }}
                      options={showrooms.map((s) => ({
                        label: s.name,
                        value: s.id,
                      }))}
                    />
                  )}
                />
              </span>
            </div>

            <div className="flex items-center gap-2 item">
              <label className="font-medium" htmlFor="account">
                {t("Calendar.appointment.form.account")}:
              </label>
              <span className="w-56">
                <Controller
                  name="accountId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <AccountSelect
                      id="account"
                      name="account"
                      placeholder="choose an account"
                      value={value || undefined}
                      onChange={(selectedAccount) => {
                        if (selectedAccount) {
                          setValue("contacts", []);
                          onChange(selectedAccount?.id);
                        }
                      }}
                      accounts={accountList}
                    />
                  )}
                />
                {errors.accountId && errors.accountId.type === "required" && (
                  <p className="text-xs italic text-primaryRed">
                    {t("Calendar.appointment.form.error.required")}
                  </p>
                )}
              </span>
            </div>
          </>
        )}
        <div className="flex flex-col gap-2 item">
          <label className="font-medium item" htmlFor="contacts">
            {t("Calendar.appointment.form.contacts")}:
          </label>
          <div className="item">
            <Controller
              name="contacts"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <MultiSelect
                  id="contacts"
                  name="contacts"
                  disabled={!accountIdSelected}
                  onChange={onChange}
                  value={value}
                  placeholder={t("Calendar.appointment.form.pick-contacts")}
                  options={(contacts || []).map<SelectOptionBase>((c) => ({
                    label: fullName(c),
                    value: c.id,
                  }))}
                  className={error && "border-primaryRed"}
                />
              )}
            />
          </div>
          {errors.contacts && errors.contacts.type === "required" && (
            <p className="text-xs italic text-primaryRed">
              {t("Calendar.appointment.form.error.required")}
            </p>
          )}
        </div>

        <div className="flex items-center gap-2 item">
          <label className="font-medium" htmlFor="date">
            {t("Calendar.appointment.form.date")}:
          </label>
          <span className="w-full lg:w-1/2">
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  id="date"
                  onChange={onChange}
                  defaultValue={value}
                />
              )}
            />
            {errors.date && errors.date.type === "required" && (
              <p className="text-xs italic text-primaryRed">
                {t("Calendar.appointment.form.error.required")}
              </p>
            )}
          </span>
        </div>

        <div className="flex items-center gap-2 item">
          <label className="font-medium" htmlFor="startTime">
            {t("Calendar.appointment.form.time")}:
          </label>
          <div className="flex items-center gap-2">
            <span>
              <Controller
                name="startTime"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TimePicker
                    id="startTime"
                    name="startTime"
                    day={dateSelected as Date}
                    placeholder="-- : --"
                    defaultValue={value}
                    onChange={(newValue) => {
                      if (newValue) {
                        const endTime = getValues("endTime");
                        if (
                          endTime &&
                          (isAfter(newValue, endTime) ||
                            isEqual(newValue, endTime))
                        ) {
                          setValue("endTime", addMinutes(newValue, 15));
                        }
                      }
                      onChange(newValue);
                    }}
                    className={error && "border-primaryRed"}
                    options={{
                      firstHourOfDay: showroom.openingHour.getHours() - 2, // start 2 hours before the opening
                      totalHours:
                        showroom.closingHour.getHours() -
                        showroom.openingHour.getHours() +
                        4, // add four hours to reach 2 hours after the closing hour
                      timeStep: SLOT_DURATION,
                    }}
                  />
                )}
              />
            </span>
            <label className="font-medium" htmlFor="endTime">
              {t("Calendar.appointment.form.time-to")}
            </label>
            <span>
              <Controller
                name="endTime"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TimePicker
                    id="endTime"
                    name="endTime"
                    day={dateSelected}
                    placeholder="-- : --"
                    defaultValue={value}
                    minTime={startTimeSelected}
                    onChange={onChange}
                    className={error && "border-primaryRed"}
                    options={{
                      firstHourOfDay: showroom.openingHour.getHours() - 2, // start 2 hours before the opening
                      totalHours:
                        showroom.closingHour.getHours() -
                        showroom.openingHour.getHours() +
                        4, // add four hours to reach 2 hours after the closing hour
                      timeStep: SLOT_DURATION,
                    }}
                  />
                )}
              />
            </span>
          </div>
          {errors && (errors.startTime || errors.endTime) && (
            <div className="-mt-6 text-sm text-primaryRed">
              {errors.startTime && errors.startTime.type === "validate" && (
                <p>{t("Calendar.appointment.form.error.time-validation")}</p>
              )}
              {errors.endTime && errors.endTime.type === "required" && (
                <p>{t("Calendar.appointment.form.error.time-required")}</p>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center gap-2 item">
          <label className="font-medium" htmlFor="type">
            {t("Calendar.appointment.form.type")}:
          </label>
          <span className="w-44">
            <Controller
              name="type"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="type"
                  name="type"
                  placeholder="choose a type"
                  defaultValue={
                    value
                      ? {
                          label: getAppointmentTypeLabel(value),
                          value,
                        }
                      : null
                  }
                  onChange={(type) => {
                    setValue("collectionId", null);
                    onChange(type);
                  }}
                  options={[
                    {
                      key: "type_grp1",
                      label: "types",
                      options: showroom.appointmentTypes.map(
                        (type: AppointmentTypeEnum) => ({
                          label: getAppointmentTypeLabel(type),
                          value: type,
                        }),
                      ),
                    },
                  ]}
                />
              )}
            />
            {errors.type && errors.type.type === "required" && (
              <p className="text-xs italic text-primaryRed">
                {t("Calendar.appointment.form.error.required")}
              </p>
            )}
          </span>
        </div>
        {typeSelected === AppointmentTypeEnum.BUYING_APPOINTMENT && (
          <div className="flex items-center gap-2 item">
            <label className="font-medium" htmlFor="collection">
              {t("Calendar.appointment.form.collection")}:
            </label>
            <span className="w-56">
              <Controller
                name="collectionId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="collection"
                    name="collection"
                    placeholder="choose a collection"
                    defaultValue={{
                      value,
                    }}
                    onChange={onChange}
                    options={[
                      {
                        key: "collection_grp1",
                        label: "collections",
                        options: availableCollections.map((s) => ({
                          label: s.name,
                          value: s.id,
                        })),
                      },
                    ]}
                  />
                )}
              />
              {errors.collectionId &&
                errors.collectionId.type === "required" && (
                  <p className="text-xs italic text-primaryRed">
                    {t("Calendar.appointment.form.error.required")}
                  </p>
                )}
            </span>
          </div>
        )}

        <div className="flex items-center gap-2 item">
          <label className="font-medium" htmlFor="format">
            {t("Calendar.appointment.form.format")}:
          </label>
          <div className="item">
            <Controller
              name="format"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="format"
                  name="format"
                  className={error && "border-primaryRed"}
                  placeholder="choose a format"
                  defaultValue={
                    value
                      ? {
                          label: getAppointmentFormatLabel(value),
                          icon: getAppointmentFormatIcon(value),
                          value,
                        }
                      : null
                  }
                  onChange={onChange}
                  options={[
                    {
                      key: "format_grp1",
                      label: "formats",
                      options: (
                        showroom.appointmentFormats || AppointmentFormatList
                      ).map((format: AppointmentFormat) => ({
                        icon: getAppointmentFormatIcon(format),
                        label: getAppointmentFormatLabel(format),
                        value: format,
                      })),
                    },
                  ]}
                />
              )}
            />
            {errors.format && errors.format.type === "required" && (
              <p className="text-xs italic text-primaryRed">
                {t("Calendar.appointment.form.error.required")}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2 item">
          <label className="font-medium item" htmlFor="sellerId">
            {t("Calendar.appointment.form.seller")}:
          </label>
          <div className="item">
            <Controller
              name="sellerId"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  id="sellerId"
                  name="sellerId"
                  placeholder="choose a seller"
                  defaultValue={{
                    value,
                  }}
                  showGroups
                  onChange={(newSellerId) => {
                    setValue("virtualMeetingApp", null);
                    setIsSellerRemoved(false);
                    onChange(newSellerId);
                  }}
                  options={sellerOptions}
                  className={error && "border-primaryRed"}
                />
              )}
            />
            {formatSelected === "VIRTUAL" && !sellerHasVirtualMeetingApps && (
              <p role="alert" className="text-xs italic text-primaryRed">
                {t(
                  "Calendar.appointment.form.error.appointment-format-not-supported",
                )}
              </p>
            )}
            {selectedSeller !== undefined &&
              !selectedSeller.appointmentTypes.includes(
                typeSelected as AppointmentTypeEnum,
              ) && (
                <p role="alert" className="text-xs italic text-primaryRed">
                  {t(
                    "Calendar.appointment.form.error.appointment-type-not-supported",
                  )}
                </p>
              )}
          </div>
          {isSellerRemoved && (
            <p className="text-xs italic text-primaryRed">
              {t("Calendar.appointment.form.error.seller-conflict")}
            </p>
          )}
          {errors.sellerId && errors.sellerId.type === "required" && (
            <p className="text-xs italic text-primaryRed">
              {t("Calendar.appointment.form.error.required")}
            </p>
          )}
        </div>

        {formatSelected === "VIRTUAL" && sellerHasVirtualMeetingApps && (
          <div className="flex flex-wrap items-center gap-2">
            <label className="font-medium" htmlFor="virtualMeetingApp">
              {t("Calendar.appointment.form.virtual-label")}:
            </label>
            <div className="w-64">
              <Controller
                name="virtualMeetingApp"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="virtualMeetingApp"
                    name="virtualMeetingApp"
                    placeholder="choose a virtual meeting tool"
                    defaultValue={
                      value
                        ? {
                            label: t(translateVirtualApp(value)),
                            value,
                          }
                        : null
                    }
                    onChange={onChange}
                    options={[
                      {
                        key: "virtual_meeting_app.grp1",
                        label: "virtual meeting tool",
                        options: selectedSeller.virtualMeetingApps.map(
                          (app: VirtualMeetingApps) => ({
                            label: t(translateVirtualApp(app)),
                            value: app,
                          }),
                        ),
                      },
                    ]}
                  />
                )}
              />
              {appointment?.virtualMeetingApp &&
                !sellerSupportsMeetingApps(
                  selectedSeller.virtualMeetingApps,
                  appointment.virtualMeetingApp,
                ) && (
                  <p role="alert" className="text-xs italic text-primaryRed">
                    {t(translateVirtualApp(appointment.virtualMeetingApp))}
                    &nbsp;
                    {t("Calendar.appointment.form.error.virtual-not-available")}
                  </p>
                )}
              {errors.virtualMeetingApp &&
                errors.virtualMeetingApp.type === "required" && (
                  <p className="text-xs italic text-primaryRed">
                    {t("Calendar.appointment.form.error.required")}
                  </p>
                )}
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end w-full gap-6 my-4">
        <Button
          theme="TERTIARY"
          testId="appointment-dialog-cancel-edit"
          onClick={onCancel}
          type="reset"
        >
          {t("Components.buttons.cancel")}
        </Button>
        <Button
          theme="PRIMARY"
          type="submit"
          disabled={isSellerRemoved || updating}
          loading={updating}
          testId="appointment-dialog-submit"
        >
          {updating && "Updating"}
          {appointment && t("Components.buttons.update")}
          {!appointment && t("Components.buttons.create")}
        </Button>
      </div>
      {onSubmitError && <div className="text-primaryRed">{onSubmitError}</div>}
      <ConfirmModal
        title={t("Calendar.AppointmentDialogEdit.competitors-scheduled-title")}
        onConfirm={handleSubmit(saveAppointment)}
        onCancel={() => setNeedsConfirmation(false)}
        show={needsConfirmation}
        confirmLabel={t(
          "Calendar.AppointmentDialogEdit.confirm-create-the-appointment",
        )}
      >
        <p>
          <Trans
            i18nKey="Calendar.AppointmentDialogEdit.competitors-scheduled-same-timeslot"
            components={{
              strong: <strong />,
              br: <br />,
              competitorsList: (
                <ul className="ml-6 my-2 list-disc">
                  {competitorsAppointments.map((ca) =>
                    ca.account ? <li key={ca.id}>{ca.account.name}</li> : null,
                  )}
                </ul>
              ),
            }}
            values={{
              accountName:
                accountList.find((a) => a.id === formAccountId)?.name || "",
            }}
          />
        </p>
      </ConfirmModal>
    </form>
  );
}

export default React.memo(AppointmentDialogEdit);
