import React from "react";

import { useTranslation } from "react-i18next";
import {
  HiOutlineCalendar,
  HiOutlineLocationMarker,
  HiOutlineUsers,
  HiOutlineVideoCamera,
} from "react-icons/hi";

import { ShowroomSeller } from "@models/OrganizationRepresentative";
import { Showroom } from "@models/Showroom";
import getShowroomOpeningPeriod from "@sales-campaign/helpers/opening-days";

interface ShowroomsListProps {
  showrooms: (Showroom & { sellers: ShowroomSeller[] })[];
}

function ShowroomsList(props: ShowroomsListProps) {
  const { showrooms } = props;
  const { t } = useTranslation();
  return (
    <>
      {showrooms.map((showroom) => {
        const virtualMeetingApps = [
          ...new Set(
            showroom.sellers.flatMap((seller) =>
              seller.virtualMeetingAppLinks
                ? Object.keys(seller.virtualMeetingAppLinks)
                : {},
            ),
          ),
        ];
        return (
          <div
            key={showroom.name}
            className="w-full p-4 rounded-lg bg-primaryLightElectricBlue"
          >
            <div className="font-bold pb-2">{showroom.name}</div>
            <div className="flex items-center gap-2 pb-2">
              <HiOutlineCalendar />
              {getShowroomOpeningPeriod(
                showroom.openingDays,
                "EEEE, MMMM do, yyyy",
              )}
            </div>
            <div className="flex items-center gap-2 pb-2 flex-wrap">
              {showroom.appointmentFormats.includes("IN_PERSON") && (
                <div className="flex items-center gap-2">
                  <HiOutlineUsers />
                  <div>{t("Booking.dashboard.in-person")}</div>
                </div>
              )}
              {showroom.appointmentFormats.includes("IN_PERSON") &&
                showroom.appointmentFormats.includes("VIRTUAL") &&
                " | "}
              {showroom.appointmentFormats.includes("VIRTUAL") && (
                <div className="flex items-center gap-2">
                  <HiOutlineVideoCamera />
                  <div>
                    {virtualMeetingApps
                      .map((virtualMeetingApp) =>
                        t(`Common.virtual-tool.${virtualMeetingApp}`),
                      )
                      .join(", ")}
                  </div>
                </div>
              )}
            </div>
            {showroom.appointmentFormats.includes("IN_PERSON") && (
              <div className="flex items-center gap-2">
                <HiOutlineLocationMarker />
                <div>{showroom.formattedAddress}</div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}

export default ShowroomsList;
