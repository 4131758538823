import React from "react";

import format from "date-fns/format";
import ReactDatePicker from "react-datepicker";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { VscCalendar } from "react-icons/vsc";

interface DatePickerProps {
  id?: string;
  placeholder?: string;
  defaultValue?: Date | null;
  onChange(date: Date): void;
  enabledDates?: Date[];
  disabled?: boolean;
}

function DatePicker(props: DatePickerProps) {
  const { disabled, defaultValue, onChange, placeholder, id, enabledDates } =
    props;

  return (
    <ReactDatePicker
      disabled={disabled}
      dateFormat="MMM do yyyy"
      includeDates={enabledDates}
      selected={defaultValue ? new Date(defaultValue) : null}
      shouldCloseOnSelect
      popperClassName="react-datepicker-popper"
      onChange={onChange}
      placeholderText="Click to select a date"
      id={id ? `${id}-datepicker` : "form-datepicker"}
      customInput={
        <div className="w-full h-full text-gray-700">
          <button
            id={id}
            data-testid="form-datepicker-button"
            type="button"
            className="flex items-center justify-center w-full gap-2 text-gray-700 border shadow cursor-pointer rounded-xl form-input focus:border-gray-700 focus:ring-gray-700"
          >
            <VscCalendar />
            <span>
              {defaultValue
                ? format(defaultValue, "MMM do, yyyy")
                : placeholder}
            </span>
          </button>
        </div>
      }
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex items-center justify-between px-2 py-2">
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            type="button"
            className={`
                  ${prevMonthButtonDisabled && "cursor-not-allowed opacity-50"}
                  inline-flex p-1 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
              `}
          >
            <HiChevronLeft className="w-5 h-5 text-gray-600" />
          </button>
          <span className="text-base text-gray-700">
            {format(date, "MMMM yyyy")}
          </span>
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            type="button"
            className={`
                  ${nextMonthButtonDisabled && "cursor-not-allowed opacity-50"}
                  inline-flex p-1 text-sm font-medium text-gray-700 bg-white
              `}
          >
            <HiChevronRight className="w-5 h-5 text-gray-600" />
          </button>
        </div>
      )}
    />
  );
}

DatePicker.defaultProps = {
  defaultValue: undefined,
  id: undefined,
  placeholder: "Select date",
};

export default DatePicker;
