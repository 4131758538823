import { SalesCampaign } from "@models/SalesCampaign";

export function isActiveSalesCampaign(salesCampaign: {
  status: SalesCampaign["status"];
}) {
  const activeStatus: SalesCampaign["status"][] = [
    "READY_TO_LAUNCH",
    "SENDING_INVITATIONS",
    "SHOWROOM_ONGOING",
  ];
  return activeStatus.includes(salesCampaign.status);
}

export default {
  isActiveSalesCampaign,
};
