import React from "react";

import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ChartData, chartColors } from "./chart-data";

// #region custom tooltip
interface CustomTooltipProps {
  t: (translationKey: string) => string;
  active?: boolean;
  payload?: { value: string }[];
  label?: string;
}

function CustomTooltip({ t, active, payload, label }: CustomTooltipProps) {
  if (active && payload && payload.length) {
    return (
      <div className="px-6 py-4 bg-white border rounded-lg border-primaryLightElectricBlue">
        <p className="label">{`${t(`Charts.tooltip.${label}`)} : ${
          payload[0].value
        }`}</p>
      </div>
    );
  }

  return null;
}
// #endregion

interface CustomBarChartProps {
  data: ChartData[];
  max: number | "auto";
  onLegendEnter?: (dataKey: string) => void;
  onLegendLeave?: () => void;
}

function CustomBarChart(props: CustomBarChartProps) {
  const { data: tmpData, onLegendEnter, onLegendLeave, max } = props;
  const { t } = useTranslation();
  const data = tmpData.filter((dt) => dt.value > 0);

  const xKey = "name";
  const yKey = "value";

  return (
    <ResponsiveContainer
      width="100%"
      height={60 * data.length + 23}
      debounce={50}
    >
      <BarChart data={data} layout="vertical" margin={{ left: 80, right: 100 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis axisLine={false} type="number" domain={[0, max]} />
        <YAxis
          yAxisId={0}
          type="category"
          axisLine={false}
          dataKey={xKey}
          tick={{
            transform: "translate(-24, 0)",
            fontSize: 16,
            textAnchor: "end",
          }}
          tickFormatter={(value) => t(`Charts.${value}`)}
        />
        <YAxis
          yAxisId={1}
          orientation="right"
          type="category"
          axisLine={false}
          dataKey={yKey}
          mirror
          tick={{
            transform: "translate(24, 0)",
            fontSize: 16,
            textAnchor: "start",
          }}
        />
        <Tooltip
          cursor={{ fill: "#F0F0FF" }}
          content={<CustomTooltip t={t} />}
        />
        <Bar
          onMouseEnter={
            onLegendEnter ? (dataKey) => onLegendEnter(dataKey) : undefined
          }
          onMouseLeave={onLegendLeave}
          minPointSize={2}
          barSize={40}
          dataKey={yKey}
        >
          {data.map((d, idx) => (
            <Cell key={d[xKey]} fill={chartColors[idx]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default CustomBarChart;
