import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Organization } from "@models/Organization";
import { Showroom } from "@models/Showroom";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";

export const showroomStatisticsSchema = z.object({
  appointments: z.object({
    format: z.record(z.string(), z.number()),
    type: z.record(z.string(), z.number()),
  }),
  invitations: z.record(z.string(), z.number()),
  invitationEmails: z.record(z.string(), z.number()),
  followUpEmails: z.record(z.string(), z.number()),
});

export type ShowroomStatistics = z.infer<typeof showroomStatisticsSchema>;

export type GetShowroomStatisticsResponse = z.infer<
  typeof getShowroomStatisticsSchema
>;

export const getShowroomStatisticsSchema = showroomStatisticsSchema;

export interface GetShowroomStatisticsParams {
  organizationId: Organization["id"];
  showroomId: Showroom["id"] | null;
}

export const getShowroomStatisticsEndpoint = {
  path: ({ organizationId, showroomId }: GetShowroomStatisticsParams) =>
    `/organizations/${organizationId}/showrooms/${showroomId}/statistics`,
  call: ({ organizationId, showroomId }: GetShowroomStatisticsParams) =>
    axiosInstance
      .get<GetShowroomStatisticsResponse>(
        getShowroomStatisticsEndpoint.path({
          organizationId,
          showroomId,
        }),
      )
      .then((response) => response.data),
};

export function useGetShowroomStatistics(params: GetShowroomStatisticsParams) {
  return useQuery({
    queryKey: getAPIQueryKey(getShowroomStatisticsEndpoint.path(params)),
    queryFn: () => getShowroomStatisticsEndpoint.call(params),
    enabled: !!params.showroomId,
  });
}
