import React, { useEffect, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { difference } from "remeda";

import {
  bookedThroughColors,
  collectionsColors,
  formatColors,
} from "@app/modules/charts/chart-data";
import CustomDoughnutChart from "@app/modules/charts/pie-chart";
import { DashboardPanel } from "@components/data-display/DashboardPanel";
import Tooltip from "@components/data-display/Tooltip";
import Loading from "@components/feedback/Loading";
import { AppointmentStatusStatistics } from "@dashboard/appointment-status-statistics";
import { InvitationStatusStatistics } from "@dashboard/invitation-status-statistics";
import OrganizationStatistics from "@dashboard/organization-statistics";
import TodaysAppointments from "@dashboard/todays-appointments";
import { formatDateToISO } from "@helpers/Date";
import { Account } from "@models/Account";
import { Appointment as AppointmentModel } from "@models/Appointment";
import { Showroom } from "@models/Showroom";
import {
  AppointmentFormat,
  InvitationStatusEnum,
  UserRoleList,
} from "@models/types/enums";
import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";
import { GetInvitations } from "@services/api/sales-campaigns/get-invitations";
import { GetShowroomAppointments } from "@services/api/showroom/get-appointments";
import { GetOngoingShowroomsEndpoint } from "@services/api/showroom/get-ongoing-showrooms";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { useAuthenticatedUser } from "@services/authentication/useAuthentication";
import { pageWithAccessControl } from "@shared/components/access-control";
import ConditionalRender from "@shared/components/on";

interface Appointment {
  id: AppointmentModel["id"];
  status: AppointmentModel["status"];
  account: Pick<Account, "id">;
}

interface Invitation {
  id: string;
  invitationStatus: InvitationStatusEnum;
  showroomId: Showroom["id"];
}

interface AppointmentForStatusStatistics {
  id: string;
  collection: {
    id: string;
    name: string;
  } | null;
  format: AppointmentFormat | null;
  bookedThrough: "BUYER_JOURNEY" | "CALENDAR" | "SPECIAL_REQUEST";
  showroom: {
    id: string;
  };
  //! fix - choose the enum once it is merged later to main
}

export function calculateInvitationStatusStatistics(invitations: Invitation[]) {
  const numberOfTotalInvitations = invitations.length;
  const numberOfBookedInvitations = invitations.filter(
    (inv) =>
      inv.invitationStatus === "BOOKED" ||
      inv.invitationStatus === "BOOKED_BY_ORGANIZATION",
  ).length;
  const numberOfFollowedUpInvitations = invitations.filter(
    (inv) => inv.invitationStatus === "FOLLOWED_UP",
  ).length;
  const numberOfNotBookedInvitations = invitations.filter(
    (inv) => inv.invitationStatus === "INVITED",
  ).length;

  return {
    numberOfTotalInvitations,
    numberOfBookedInvitations,
    numberOfFollowedUpInvitations,
    numberOfNotBookedInvitations,
  };
}

export function calculateAppointmentStatusStatistics(
  appointments: Appointment[],
) {
  const totalAppointmentsToday = appointments.length;
  const ongoingAppointmentsNow = appointments.filter(
    (appt) => appt.status === "ONGOING",
  ).length;
  const buyersWaitingNow = appointments.filter(
    (appt) => appt.status === "BUYER_ARRIVED",
  ).length;
  const completedAppointmentsToday = appointments.filter(
    (appt) => appt.status === "DONE",
  ).length;
  const canceledAppointmentsToday = appointments.filter(
    (appt) => appt.status === "CANCELLED",
  ).length;

  return {
    totalAppointmentsToday,
    ongoingAppointmentsNow,
    buyersWaitingNow,
    completedAppointmentsToday,
    canceledAppointmentsToday,
  };
}

export function calculateBookingDetailsStatistics(
  appointments: AppointmentForStatusStatistics[],
) {
  // booked through statistics
  // const totalAppointments = appointments.length;
  const bookedThroughBuyerJourney = appointments.filter(
    (appt) => appt.bookedThrough === "BUYER_JOURNEY",
  ).length;
  const bookedThroughCalendar = appointments.filter(
    (appt) => appt.bookedThrough === "CALENDAR",
  ).length;
  const bookedThroughSpecialRequest = appointments.filter(
    (appt) => appt.bookedThrough === "SPECIAL_REQUEST",
  ).length;

  // format statistics
  const virtualAppointments = appointments.filter(
    (appt) => appt.format === "VIRTUAL",
  ).length;
  const inPersonAppointments = appointments.filter(
    (appt) => appt.format === "IN_PERSON",
  ).length;

  return {
    bookedThroughBuyerJourney,
    bookedThroughCalendar,
    bookedThroughSpecialRequest,
    virtualAppointments,
    inPersonAppointments,
  };
}

export function usePaginationState(filteredAppointments: Appointment[]) {
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfItemsPerPage = 20;
  const {
    paginatedAppointments,
    canGoToPreviousPage,
    canGoToNextPage,
    allPageNumbers,
    numberOfAppointmetntsPerSeller,
  } = useMemo(() => {
    const startIndex = (currentPage - 1) * numberOfItemsPerPage;
    const endIndex = startIndex + numberOfItemsPerPage;
    const appointments = filteredAppointments.slice(startIndex, endIndex);
    const appointmetntsPerSeller = filteredAppointments.length;

    const totalNumberOfPages = Math.ceil(
      filteredAppointments.length / numberOfItemsPerPage,
    );

    const pageNumbers: (number | string)[] = [];
    if (totalNumberOfPages > 5) {
      // add the first page
      pageNumbers.push(1);
      if (currentPage > 3) {
        // add ellipsis
        pageNumbers.push("...");
      }
      // add page numbers around the current page
      for (
        // ensure we start at page 2 or two pages before the currentPage
        let i = Math.max(2, currentPage - 2);
        // ensure we end at the second last page or two pages after the currentPage
        i <= Math.min(totalNumberOfPages - 1, currentPage + 2);
        i++
      ) {
        pageNumbers.push(i);
      }
      // add ellipsis after current page range
      if (currentPage < totalNumberOfPages - 2) {
        pageNumbers.push("...");
      }
      // add the last page
      pageNumbers.push(totalNumberOfPages);
    } else {
      // list all page numbers
      for (let i = 1; i <= totalNumberOfPages; i++) {
        pageNumbers.push(i);
      }
    }

    return {
      paginatedAppointments: appointments,
      canGoToPreviousPage: currentPage > 1,
      canGoToNextPage: currentPage < totalNumberOfPages,
      allPageNumbers: pageNumbers,
      numberOfAppointmetntsPerSeller: appointmetntsPerSeller,
    };
  }, [filteredAppointments, currentPage]);

  return {
    paginatedAppointments,
    canGoToPreviousPage,
    canGoToNextPage,
    allPageNumbers,
    numberOfAppointmetntsPerSeller,
    currentPage,
    setCurrentPage,
    numberOfItemsPerPage,
  };
}

function DashboardViewComponent() {
  const { t } = useTranslation();
  const currentUser = useAuthenticatedUser();

  //* fetch organization id, showroom id and today's date for GetDailyCalendarEndpoint query
  // organization Id
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  // showroom Ids
  const { data: allOngoingShowrooms = [], status: allOngoingShowroomsStatus } =
    GetOngoingShowroomsEndpoint.useHook({
      organizationId,
    });

  // today's date
  const todaysDate = formatDateToISO(new Date());

  //* get daily calendar data
  const { data: rawDailyCalendar, status: dailyCalendarFetchStatus } =
    useQueries({
      queries: allOngoingShowrooms.map((ongoingShowroom) =>
        GetDailyCalendarEndpoint.query({
          organizationId,
          showroomId: ongoingShowroom.id,
          dayAsString: todaysDate,
        }),
      ),
      combine: (queries) => ({
        data: queries.flatMap((q) => q.data || []),
        error: queries.find((q) => q.error)?.error,
        status:
          queries.find((q) => q.status === "error")?.status ||
          queries.find((q) => q.status === "pending")?.status ||
          "success",
      }),
    });

  // const [selectedSellerId, setSelectedSellerId] = useState<string | "all">(
  //   "all",
  // );

  const allAppointments = rawDailyCalendar
    .filter((day) => day.appointments.length > 0)
    .flatMap((day) => day.appointments)
    .filter((app) => app.type !== "BUSY")
    .sort((a, b) => {
      // convert start times to local date/time and get the timestamps
      const startTimeA = new Date(
        a.startTime.toLocalDate(a.showroom.timezone),
      ).getTime();
      const startTimeB = new Date(
        b.startTime.toLocalDate(b.showroom.timezone),
      ).getTime();

      // compare the timestamps
      return startTimeA - startTimeB;
    });

  const {
    totalAppointmentsToday,
    ongoingAppointmentsNow,
    buyersWaitingNow,
    completedAppointmentsToday,
    canceledAppointmentsToday,
  } = useMemo(
    () => calculateAppointmentStatusStatistics(allAppointments),
    [allAppointments],
  );

  //* handle pagination
  // set the filtered appointments
  // const filteredAppointments = useMemo(() => {
  //   if (selectedSellerId === "all") {
  //     return allAppointments;
  //   }

  //   return allAppointments.filter(
  //     (appointment) => appointment.seller.id === selectedSellerId,
  //   );
  // }, [allAppointments, selectedSellerId]);

  // pagination data
  // const paginationState = usePaginationState(filteredAppointments);

  const isLoading =
    dailyCalendarFetchStatus === "pending" ||
    allOngoingShowroomsStatus === "pending";

  //* invitation status statistics
  const [selectedShowroomId, setSelectedShowroomId] = useState<string | "all">(
    "all",
  );

  // fetch invitations data
  const { data: rawInvitations } = useQueries({
    queries: allOngoingShowrooms.map((ongoingShowroom) => ({
      queryKey: ["invitations", organizationId, ongoingShowroom.id, todaysDate],
      queryFn: () =>
        GetInvitations.call({
          organizationId,
          season: ongoingShowroom.season,
          year: ongoingShowroom.year,
        }),
    })),
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  const invitationsForOngoingShowrooms = useMemo(() => {
    const ongoingShowroomIds = allOngoingShowrooms.map(
      (showroom) => showroom.id,
    );
    return rawInvitations.filter((invitation) =>
      ongoingShowroomIds.includes(invitation.showroomId),
    );
  }, [allOngoingShowrooms, rawInvitations]);

  // set the filtered invitations
  const filteredInvitations = useMemo(() => {
    if (selectedShowroomId === "all") {
      return invitationsForOngoingShowrooms;
    }

    return invitationsForOngoingShowrooms.filter(
      (invitation) => invitation.showroomId === selectedShowroomId,
    );
  }, [invitationsForOngoingShowrooms, selectedShowroomId]);

  // compute the invitation status statistics
  const {
    numberOfTotalInvitations,
    numberOfBookedInvitations,
    numberOfFollowedUpInvitations,
    numberOfNotBookedInvitations,
  } = useMemo(
    () => calculateInvitationStatusStatistics(filteredInvitations),
    [filteredInvitations],
  );

  //* booking details statistics

  // fetch invitations data
  const { data: rawAppointments } = useQueries({
    queries: allOngoingShowrooms.map((ongoingShowroom) => ({
      queryKey: GetShowroomAppointments.getQueryKeys({
        organizationId,
        showroomId: ongoingShowroom.id,
      }),
      queryFn: () =>
        GetShowroomAppointments.call({
          organizationId,
          showroomId: ongoingShowroom.id,
        }),
    })),
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  // remove all "busy" slots
  const appointmentsForOngoingShowrooms: AppointmentForStatusStatistics[] =
    useMemo(
      () => rawAppointments.filter((appt) => appt.type !== "BUSY"),
      [rawAppointments],
    );

  // compute the booking status statistics
  const filteredAppointmentsForBookingDetails = useMemo(() => {
    console.log("HERE");
    if (selectedShowroomId === "all") {
      return appointmentsForOngoingShowrooms;
    }

    return appointmentsForOngoingShowrooms.filter(
      (appointment) => appointment.showroom.id === selectedShowroomId,
    );
  }, [appointmentsForOngoingShowrooms, selectedShowroomId]);

  const {
    bookedThroughBuyerJourney,
    bookedThroughCalendar,
    bookedThroughSpecialRequest,
    virtualAppointments,
    inPersonAppointments,
  } = useMemo(
    () =>
      calculateBookingDetailsStatistics(filteredAppointmentsForBookingDetails),
    [filteredAppointmentsForBookingDetails],
  );

  const bookedThroughData = [
    {
      name: "Buyer Journey",
      donutChart: "booked_through",
      value: bookedThroughBuyerJourney,
      color: bookedThroughColors[0],
    },
    {
      name: "Calendar",
      donutChart: "booked_through",
      value: bookedThroughCalendar,
      color: bookedThroughColors[1],
    },
    {
      name: "Special Request",
      donutChart: "booked_through",
      value: bookedThroughSpecialRequest,
      color: bookedThroughColors[2],
    },
  ];

  const formatData = [
    {
      name: "In Person",
      donutChart: "format_data",
      value: inPersonAppointments,
      color: formatColors[0],
    },
    {
      name: "Virtual",
      donutChart: "format_data",
      value: virtualAppointments,
      color: formatColors[1],
    },
  ];

  // booking details - collections data
  const collectionCountMap = new Map();

  let colorIndex = 0;

  const getNextColor = () => {
    const color = collectionsColors[colorIndex];
    colorIndex = (colorIndex + 1) % collectionsColors.length;
    return color;
  };

  filteredAppointmentsForBookingDetails.forEach(({ collection }) => {
    // make sure that only buying appointments, and not walkthroughs are included
    if (collection !== null) {
      const { id, name } = collection;
      if (collectionCountMap.has(id)) {
        collectionCountMap.get(id).value += 1;
      } else {
        const color = getNextColor();
        collectionCountMap.set(id, { name, value: 1, color: `#${color}` });
      }
    }
  });

  const collectionData = Array.from(collectionCountMap.values());

  // const [selectedShowroomId, setSelectedShowroomId] = useState<string | "all">(
  //   "all",
  // );

  // BOOKING DETAILS - filtered appointments
  // const filteredAppointmentsForBookingDetails = useMemo(() => {
  //   if (selectedSellerId === "all") {
  //     return allAppointments;
  //   }

  //   return allAppointments.filter(
  //     (appointment) => appointment.seller.id === selectedSellerId,
  //   );
  // }, [selectedSellerId]);

  useEffect(() => console.log(rawAppointments), [rawAppointments]);

  return (
    <div className="h-full flex flex-col relative">
      <div className="bg-primaryLightElectricBlue h-[168px] w-full" />
      <div className="h-full w-full flex flex-col absolute pb-12">
        <div className="bg-primaryLightElectricBlue pt-12 px-6">
          <div className="pb-6">
            <h1 className="heading-1 pb-2">
              <Trans
                i18nKey="Dashboard.greeting"
                values={{ firstName: currentUser.firstName }}
              >
                Dashboard.greeting
              </Trans>
            </h1>
            {t("Dashboard.welcome")}
          </div>
        </div>

        <div>
          <div className="grid grid-cols-10 gap-7 ml-7 mr-7">
            {/* Appointment Status */}
            <AppointmentStatusStatistics
              totalAppointmentsToday={totalAppointmentsToday}
              completedAppointmentsToday={completedAppointmentsToday}
              canceledAppointmentsToday={canceledAppointmentsToday}
              ongoingAppointmentsNow={ongoingAppointmentsNow}
              buyersWaitingNow={buyersWaitingNow}
            />

            {/* Today's Appointments */}
            <TodaysAppointments />

            {/* Overall Performance */}
            <DashboardPanel
              className="col-span-3"
              //! uncomment below later
              // title={t("Dashboard.overall-performance.title")}
            >
              <div className="p-5 text-center">
                <p className="text-2xl pb-5 text-primaryElectricBlue font-bold">
                  {t("Dashboard.overall-performance.coming-soon")}
                </p>
                <p className="text-sm">
                  {t(
                    "Dashboard.overall-performance.overall-performance-statistics",
                  )}
                </p>
              </div>
            </DashboardPanel>

            {/* Invitation Status */}
            <div className="col-span-7">
              <div
                style={{ boxShadow: "0px 0px 16px 0px rgba(45, 42, 54, 0.08)" }}
                className={` bg-white p-6 mb-4 rounded-lg grow-x col-span-9`}
              >
                <div className="flex flex-row justify-between">
                  <div className="flex items-center">
                    <h3 className="font-bold">
                      {t("Dashboard.invitation-status.title")}
                    </h3>
                    <Tooltip
                      placement="bottom-start"
                      renderIf={numberOfTotalInvitations > 0}
                      fallbackProp="children"
                      content={
                        <div className="min-w-[5rem] max-w-[21rem]">
                          <p className="text-center">
                            Total Invitations: {numberOfTotalInvitations}
                          </p>
                        </div>
                      }
                    >
                      <div className="bg-primaryElectricBlue w-8 h-8 rounded-full flex items-center justify-center text-white ml-1">
                        {numberOfTotalInvitations}
                      </div>
                    </Tooltip>
                  </div>
                  {allOngoingShowrooms.length > 1 && (
                    <div className="flex flex-row border border-transparent rounded-md">
                      <p className="flex items-center text-sm p-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-l-md">
                        {t("Dashboard.invitation-status.for-showroom")}
                      </p>
                      <select
                        id="seller-dropdown"
                        value={selectedShowroomId}
                        onChange={(e) => {
                          setSelectedShowroomId(e.target.value as string);
                        }}
                        className="form-select block border rounded-r-md shadow-sm border-primaryElectricBlue focus:border-primaryElectricBlue bg-primaryLightElectricBlue sm:text-sm text-primaryElectricBlue caret-primaryElectricBlue"
                      >
                        <option value="all">All</option>
                        {allOngoingShowrooms?.map((showroom) => (
                          <option key={showroom.id} value={showroom.id}>
                            {showroom.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                {isLoading ? (
                  <Loading type="screen" />
                ) : (
                  <ConditionalRender
                    renderIf={numberOfTotalInvitations > 0}
                    fallback={
                      <div className="flex items-center justify-center h-64">
                        <p className="text-lg text-gray-500">
                          {t("Dashboard.todays-appointments.no-appointments")}
                        </p>
                      </div>
                    }
                  >
                    <InvitationStatusStatistics
                      numberOfTotalInvitations={numberOfTotalInvitations}
                      numberOfBookedInvitations={numberOfBookedInvitations}
                      numberOfFollowedUpInvitations={
                        numberOfFollowedUpInvitations
                      }
                      numberOfNotBookedInvitations={
                        numberOfNotBookedInvitations
                      }
                    />
                  </ConditionalRender>
                )}
              </div>
            </div>

            {/* Booking Details */}
            <DashboardPanel
              className="col-span-10"
              title={t("Dashboard.booking-details.title")}
              filterContent={
                allOngoingShowrooms.length > 1 && (
                  <div className="flex flex-row border border-transparent rounded-md">
                    <p className="flex items-center text-sm p-1 bg-primaryLightestGrey text-primaryDarkGrey rounded-l-md">
                      {t("Dashboard.invitation-status.for-showroom")}
                    </p>
                    <select
                      id="seller-dropdown"
                      value={selectedShowroomId}
                      onChange={(e) => {
                        setSelectedShowroomId(e.target.value as string);
                      }}
                      className="form-select block border rounded-r-md shadow-sm border-primaryElectricBlue focus:border-primaryElectricBlue bg-primaryLightElectricBlue sm:text-sm text-primaryElectricBlue caret-primaryElectricBlue"
                    >
                      <option value="all">All</option>
                      {allOngoingShowrooms?.map((showroom) => (
                        <option key={showroom.id} value={showroom.id}>
                          {showroom.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )
              }
            >
              <div className="flex flex-col justify-center">
                <div className="flex flex-wrap md:flex-row justify-center">
                  <div>
                    {/* Booked Through Donut Chart */}
                    <CustomDoughnutChart
                      data={bookedThroughData}
                      subtitle="Booked Through"
                    />
                  </div>
                  <div>
                    {/* Collections  Donut Chart */}
                    <CustomDoughnutChart
                      data={collectionData}
                      subtitle="Collections"
                    />
                  </div>
                  <div>
                    {/* Format  Donut Chart */}
                    <CustomDoughnutChart data={formatData} subtitle="Format" />
                  </div>
                </div>
              </div>
            </DashboardPanel>
          </div>
        </div>
        {/* --------------------- delete everything below later */}
        <div style={{ margin: "120px 0" }} />
        <div className="flex grow">
          <OrganizationStatistics />
        </div>
      </div>
    </div>
  );
}
const DashboardView = React.memo(DashboardViewComponent);

export default pageWithAccessControl(
  difference(UserRoleList, ["FREELANCE"]), // everyone but freelancers
  DashboardView,
);
