import React, { PropsWithChildren } from "react";

import { UserRole } from "@models/types/enums";
import NotFound from "@pages/404";
import useAuthentication from "@services/authentication/useAuthentication";
import { hasRoleOrIsAdmin } from "@shared/components/access-control/helpers";

interface AccessControlProps extends PropsWithChildren {
  roles: UserRole[];
  fallback?: React.ReactNode;
  fallbackNotAuthenticated?: React.ReactNode;
}

/**
 * This component makes sure that the current user has the proper role before displaying its children
 *
 * @prop roles lists the roles that are allowed to see the children
 * @prop fallback is the component displayed if the user does NOT have the proper role
 * @prop fallbackNotAuthenticated is the component displayed if the user is NOT authenticated
 */
export function AccessControl({
  roles,
  children,
  fallback = null,
  fallbackNotAuthenticated = null,
}: AccessControlProps) {
  const { currentUser } = useAuthentication();
  if (!currentUser) {
    return fallbackNotAuthenticated;
  }

  return hasRoleOrIsAdmin(currentUser.role, roles) ? children : fallback;
}

/**
 * This HOC should be used for components only (not pages)
 * it will help wrap a component under a role-defined access control
 * It will not display the component if the roles do not match or the user is not authenticated
 */
export function componentAccessControl<P = {}>(
  roles: UserRole[],
  Component: React.ComponentType<P>,
) {
  return React.forwardRef((props: P, ref) => (
    <AccessControl roles={roles}>
      <Component {...props} ref={ref} />
    </AccessControl>
  ));
}

/**
 * This HOC should be used only for pages components
 * it will help wrap a page under a role-defined access control
 * It will display a 404 page if the roles do not match or if the user is not authenticated
 */
export function pageWithAccessControl<P = {}>(
  roles: UserRole[],
  Component: React.ComponentType<P>,
) {
  return React.forwardRef((props: P, ref) => (
    <AccessControl
      roles={roles}
      fallback={<NotFound />}
      fallbackNotAuthenticated={<NotFound />}
    >
      <Component {...props} ref={ref} />
    </AccessControl>
  ));
}

export function withAccessControl<P = {}>(
  roles: UserRole[],
  Component: React.ComponentType<P>,
) {
  return React.forwardRef((props: P, ref) => (
    <AccessControl
      roles={roles}
      fallback={null}
      fallbackNotAuthenticated={null}
    >
      <Component {...props} ref={ref} />
    </AccessControl>
  ));
}

/**
 * This HOC helps create components with an additional prop "visibleByRoles"
 *
 */

export interface AddedAccessControlProps {
  aclRoles?: AccessControlProps["roles"];
  aclFallback?: AccessControlProps["fallback"];
  aclFallbackNotAuthenticated?: AccessControlProps["fallbackNotAuthenticated"];
}
export function addAccessControlProps<P = {}>(
  Component: React.ComponentType<P>,
) {
  return React.forwardRef((props: P & AddedAccessControlProps, ref) => {
    const {
      aclRoles,
      aclFallback = null,
      aclFallbackNotAuthenticated = null,
    } = props;

    if (aclRoles !== undefined) {
      return (
        <AccessControl
          roles={aclRoles}
          fallback={aclFallback}
          fallbackNotAuthenticated={aclFallbackNotAuthenticated}
        >
          <Component {...props} ref={ref} />
        </AccessControl>
      );
    }

    return <Component {...props} />;
  });
}
